import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemText, ListItemIcon, Typography, Toolbar } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import LocalActivity from '@mui/icons-material/LocalActivity';
import Person from '@mui/icons-material/Person';
import AirplaneTicket from '@mui/icons-material/AirplaneTicket';
import Room from '@mui/icons-material/Room';
import Book from '@mui/icons-material/Book';
import SellIcon from '@mui/icons-material/Sell';

const drawerWidth = 240;

const Sidebar = () => {
  const location = useLocation();

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: 'border-box',
          top: 0,
          zIndex: 1
        }
      }}
    >
      <Toolbar>
        <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}></Typography>
      </Toolbar>
      <List>
        {[
          { text: 'Home', icon: <HomeIcon />, to: '/' },
          { text: 'Activities', icon: <LocalActivity />, to: '/activities' },
          { text: 'Coaches', icon: <Person />, to: '/coaches' },
          {
            text: 'Reservations',
            icon: <AirplaneTicket />,
            to: '/reservations'
          },
          { text: 'Rooms', icon: <Room />, to: '/rooms' },
          { text: 'Sessions', icon: <Book />, to: '/sessions' },
          { text: 'Special Offer', icon: <SellIcon />, to: '/special-offer' }
        ].map((item, index) => (
          <ListItem
            button
            key={item.text}
            component={NavLink}
            to={item.to}
            exact
            activeClassName="Mui-selected"
            sx={{
              backgroundColor: location.pathname === item.to ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.05)'
              }
            }}
          >
            <ListItemIcon sx={{ color: 'inherit' }}>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;
