import DeleteConfirmationModal from '../components/common/DeleteConfirmationModal';
import ReservationModal from '../components/Reservations/ReservationModal';
import React, { useEffect, useState } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Paper, Box, Pagination, Alert, Snackbar, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { fetchAllReservations, useReservationsContext, deleteReservation } from '../store/reservations';
import DatePickerValue from '../components/common/DatePickerValue';

const Reservations = () => {
  const { state, dispatch } = useReservationsContext();
  const reservations = state.all || [];
  const pagination = state.pagination;
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editAddModalOpen, setEditAddModalOpen] = useState(false);
  const [currentReservation, setCurrentReservation] = useState(null);
  const [reservationToDelete, setReservationToDelete] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState(false);
  const [severity, setSeverity] = useState(false);
  const [dateTimeFilter, setDateTimeFilter] = useState(null);
  const [name, setName] = useState(null);

  const pageSize = 10;

  useEffect(() => {
    let jwt = localStorage.getItem('jwt');
    fetchAllReservations(dispatch, {
      jwt,
      pageSize,
      page: activePage,
      name,
      dateTimeFilter
    });
  }, [activePage, name, dateTimeFilter]);

  const handleEdit = (Reservation) => {
    setCurrentReservation(Reservation);
    setEditAddModalOpen(true);
  };

  const handleDelete = ({ id, id_user }) => {
    let jwt = localStorage.getItem('jwt');

    deleteReservation(dispatch, { jwt, id, id_user }).then((res) => {
      setOpen(true);
      if (res === 'OK') {
        fetchAllReservations(dispatch, {
          jwt,
          pageSize,
          page: activePage,
          name,
          dateTimeFilter
        });
        setMsg('Reservation deleted Successfully');
        setSeverity('success');
      } else {
        setMsg('Problem while deleting Reservation');
        setSeverity('error');
      }
    });

    setDeleteModalOpen(false);
  };

  const handleSave = ({ message, variant }) => {
    setOpen(true);
    setMsg(message);
    setSeverity(variant);
    setEditAddModalOpen(false);
    let jwt = localStorage.getItem('jwt');
    fetchAllReservations(dispatch, {
      jwt,
      pageSize,
      page: activePage,
      name,
      dateTimeFilter
    });
  };

  const handlePageChange = (event, page) => {
    setActivePage(page);
  };

  const formatDate = (datetime) => {
    const date = new Date(datetime);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
    return formattedDate;
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3
        }}
      >
        <Typography variant="h4">Reservations</Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 4 }}>
        <DatePickerValue value={dateTimeFilter} setValue={setDateTimeFilter} title={'Date'}>
          {' '}
        </DatePickerValue>

        <TextField autoFocus margin="dense" label="Name" type="text" fullWidth value={name} onChange={(e) => setName(e.target.value)} />
      </Box>
      <TableContainer component={Paper} sx={{ marginTop: 2, overflowX: 'auto' }}>
        <Table sx={{ tableLayout: 'fixed', width: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ p: 1, width: '5%' }}>id</TableCell>
              <TableCell sx={{ p: 1, width: '15%' }}>Session</TableCell>
              <TableCell sx={{ p: 1, width: '15%' }}>User</TableCell>
              <TableCell sx={{ p: 1, width: '15%' }}>Coach</TableCell>
              <TableCell sx={{ p: 1, width: '15%' }}>status</TableCell>
              <TableCell sx={{ p: 1, width: '15%' }}>date</TableCell>
              <TableCell sx={{ p: 1, width: '20%' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reservations.map((reservation) => (
              <TableRow key={reservation.id}>
                <TableCell
                  sx={{
                    p: 1,
                    width: '5%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {reservation.id}
                </TableCell>
                <TableCell
                  sx={{
                    p: 1,
                    width: '15%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {reservation.session.name}
                </TableCell>
                <TableCell
                  sx={{
                    p: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '15%'
                  }}
                >
                  {reservation.user_account.firstname} {reservation.user_account.lastname} ({reservation.user_account.phone})
                </TableCell>
                <TableCell
                  sx={{
                    p: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '15%'
                  }}
                >
                  {reservation.session.coach.firstname} {reservation.session.coach.lastname}
                </TableCell>
                <TableCell
                  sx={{
                    p: 1,
                    width: '15%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {reservation.status}
                </TableCell>
                <TableCell
                  sx={{
                    p: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '15%'
                  }}
                >
                  {formatDate(reservation.datetime)}
                </TableCell>
                <TableCell sx={{ p: 1, width: '20%' }}>
                  <IconButton onClick={() => handleEdit(reservation)} color="primary">
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setReservationToDelete({
                        id: reservation.id,
                        id_user: reservation.user.id
                      });
                      setDeleteModalOpen(true);
                    }}
                    color="secondary"
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        {pagination && <Pagination count={pagination.pageCount} page={activePage.page} onChange={handlePageChange} siblingCount={1} boundaryCount={1} shape="rounded" variant="outlined" />}
      </Box>
      <DeleteConfirmationModal
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        handleConfirm={() => handleDelete(reservationToDelete)}
        title="Are you sure you want to delete this Reservation?"
      />
      <ReservationModal open={editAddModalOpen} handleClose={() => setEditAddModalOpen(false)} handleSave={handleSave} initialData={currentReservation} />

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} variant="filled" sx={{ width: '100%' }}>
          {msg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Reservations;
