import React, { useEffect } from 'react';
import { getMe, useUserContext } from '../store/user';
import { useNavigate } from 'react-router-dom';

const AuthLayout = ({ children }) => {
  const { dispatch } = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    let jwt = localStorage.getItem('jwt');
    getMe(dispatch, { jwt }).then((res) => {
      if (res) {
        navigate('/');
      }
    });
  }, []);

  return <div>{children}</div>;
};

export default AuthLayout;
