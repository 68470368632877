import DeleteConfirmationModal from '../components/common/DeleteConfirmationModal';
import EditAddActivityModal from '../components/Activities/EditAddActivityModal';
import React, { useEffect, useState } from 'react';
import { Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Paper, Box, Pagination, Alert, Snackbar } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { fetchAllActivities, useActivitiesContext, deleteActivity } from '../store/activities';

const Activities = () => {
  const { state, dispatch } = useActivitiesContext();
  const activities = state.all || [];
  const pagination = state.pagination;
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editAddModalOpen, setEditAddModalOpen] = useState(false);
  const [currentActivity, setCurrentActivity] = useState(null);
  const [activityToDelete, setActivityToDelete] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState(false);
  const [severity, setSeverity] = useState(false);

  const pageSize = 10;

  useEffect(() => {
    let jwt = localStorage.getItem('jwt');
    fetchAllActivities(dispatch, { jwt, pageSize, page: activePage });
  }, [activePage]);

  const handleEdit = (activity) => {
    setCurrentActivity(activity);
    setEditAddModalOpen(true);
  };

  const handleDelete = (id) => {
    let jwt = localStorage.getItem('jwt');

    deleteActivity(dispatch, { jwt, id }).then((res) => {
      setOpen(true);
      if (res === 'OK') {
        fetchAllActivities(dispatch, { jwt, id });
        setMsg('Activity deleted Successfully');
        setSeverity('success');
      } else {
        setMsg('Problem while deleting Activity');
        setSeverity('error');
      }
    });

    setDeleteModalOpen(false);
  };

  const handleSave = ({ message, variant }) => {
    setOpen(true);
    setMsg(message);
    setSeverity(variant);
    setEditAddModalOpen(false);
    let jwt = localStorage.getItem('jwt');
    fetchAllActivities(dispatch, { jwt, pageSize, page: 1 });
  };

  const handlePageChange = (event, page) => {
    setActivePage(page);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3
        }}
      >
        <Typography variant="h4">Activities</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setCurrentActivity(null);
            setEditAddModalOpen(true);
          }}
        >
          Add
        </Button>
      </Box>
      <TableContainer component={Paper} sx={{ marginTop: 2, overflowX: 'auto' }}>
        <Table sx={{ tableLayout: 'fixed', width: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ p: 1, width: '20%' }}>Name</TableCell>
              <TableCell sx={{ p: 1, width: '60%' }}>Description</TableCell>
              <TableCell sx={{ p: 1, width: '10%' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activities.map((activity) => (
              <TableRow key={activity.id}>
                <TableCell
                  sx={{
                    p: 1,
                    width: '20%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {activity.name}
                </TableCell>
                <TableCell
                  sx={{
                    p: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '60%'
                  }}
                >
                  {activity.description}
                </TableCell>
                <TableCell sx={{ p: 1, width: '10%' }}>
                  <IconButton onClick={() => handleEdit(activity)} color="primary">
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setActivityToDelete(activity.id);
                      setDeleteModalOpen(true);
                    }}
                    color="secondary"
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        {pagination && <Pagination count={pagination.pageCount} page={activePage.page} onChange={handlePageChange} siblingCount={1} boundaryCount={1} shape="rounded" variant="outlined" />}
      </Box>
      <DeleteConfirmationModal
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        handleConfirm={() => handleDelete(activityToDelete)}
        title="Are you sure you want to delete this activity?"
      />
      <EditAddActivityModal open={editAddModalOpen} handleClose={() => setEditAddModalOpen(false)} handleSave={handleSave} initialData={currentActivity} />

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} variant="filled" sx={{ width: '100%' }}>
          {msg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Activities;
