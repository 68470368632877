import React from 'react';
import StoreProvider from './store';
import Router from './Router';

const App = () => (
  <StoreProvider>
    <Router />
  </StoreProvider>
);

export default App;
