import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Typography, Paper, Box, IconButton, Grid, Alert, Toolbar, AppBar, Autocomplete } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { updateSession, createSession, useSessionsContext } from '../../store/sessions';
import CloseIcon from '@mui/icons-material/Close';
import { fetchAllRooms, useRoomsContext } from '../../store/rooms';
import { fetchAllCoaches, useUserContext } from '../../store/user';
import { fetchAllActivities, useActivitiesContext } from '../../store/activities';
import LayoutCard from '../Rooms/LayoutCard';
import DateTimePickerValue from '../common/DateTimePickerValue';
import ScheduleManager from '../common/ScheduleManager';
import dayjs from 'dayjs';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const EditAddSessionModal = ({ open, handleClose, handleSave, initialData }) => {
  const [name, setName] = useState('');
  const [price, setPrice] = useState(0);
  const [duration, setDuration] = useState(0);
  const [description, setDescription] = useState('');
  const [photos, setPhotos] = useState([]);
  const [files, setFiles] = useState([]);
  const { dispatch } = useSessionsContext();
  const { dispatch: dispatchRooms, state: stateRooms } = useRoomsContext();
  const { dispatch: dispatchUsers, state: stateUsers } = useUserContext();
  const { dispatch: dispatchActivities, state: stateActivities } = useActivitiesContext();
  const [layouts, setLayouts] = useState([]);
  const [lines, setLines] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [selectedCoach, setSelectedCoach] = useState(null);
  const [selectedLayout, setSelectedLayout] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [dates, setDates] = useState([]);
  const [defaultDates, setDefaultDates] = useState([]);
  const activities = stateActivities.all;
  const coaches = stateUsers.all;
  const rooms = stateRooms.all;
  const [frequency, setFrequency] = useState('');

  useEffect(() => {
    if (selectedRoom) {
      setLayouts(selectedRoom.room_layouts);
      const initialLines = selectedRoom.lines.map((l) => ({
        length: l.length,
        points: [l.start_x, l.start_y, l.end_x, l.end_y]
      }));
      setLines(JSON.parse(JSON.stringify(initialLines)));
    } else {
      setLines([]);
      setLayouts([]);
    }
  }, [selectedRoom]);

  const fetchData = () => {
    let jwt = localStorage.getItem('jwt');
    fetchAllRooms(dispatchRooms, { jwt });
    fetchAllCoaches(dispatchUsers, { jwt });
    fetchAllActivities(dispatchActivities, { jwt });
  };

  useEffect(() => {
    if (initialData) {
      setFrequency(initialData?.frequency);
      setPrice(initialData?.price);
      setDuration(initialData?.duration);
      setName(initialData?.name);
      setSelectedActivity(initialData?.activity);
      setSelectedCoach(initialData?.coach);
      setSelectedRoom(rooms.find((r) => r.id === initialData?.room_layout?.room?.id));
      setSelectedLayout(initialData?.room_layout);
      setDescription(initialData?.description);
      setPhotos(initialData.photos || []);
      setStartDate(dayjs(initialData?.start_date));
      setEndDate(initialData?.end_date ? dayjs(initialData?.end_date) : null);
      setDefaultDates(initialData?.dates.map((el) => el?.value));
    } else {
      setPrice(0);
      setDuration(0);
      setFrequency('');
      setName('');
      setDescription('');
      setPhotos([]);
      setFiles([]);
      setSelectedActivity(null);
      setSelectedCoach(null);
      setSelectedRoom(null);
      setSelectedCoach(null);
      setStartDate(null);
      setEndDate(null);
      setDefaultDates([]);
    }
  }, [initialData]);

  const handleDeletePhoto = (index) => {
    const updatedPhotos = [...photos];
    updatedPhotos.splice(index, 1);
    setPhotos(updatedPhotos);
  };
  const handleDeleteFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };
  useEffect(() => {
    setErrorMsg('');
  }, [name, startDate, price, selectedActivity, selectedCoach, selectedLayout, duration]);

  const handleFormSubmit = () => {
    let msg = '';
    if (!name) {
      msg += 'Add a Name\n';
    }
    if (!startDate) {
      msg += 'Add start Date\n';
    }
    if (!price) {
      msg += 'Add Price\n';
    }
    if (!duration) {
      msg += 'Add Duration\n';
    }
    if (!selectedActivity) {
      msg += 'Add Activity\n';
    }
    if (!selectedCoach) {
      msg += 'Add Coach\n';
    }
    if (!selectedLayout) {
      msg += 'Add a room\n';
    }
    if (msg) {
      setErrorMsg(msg);
      return;
    }
    const formData = new FormData();
    let uploadFiles = false;
    for (let index = 0; index < files.length; index++) {
      uploadFiles = true;
      const file = files[index];
      formData.append('files', file);
    }
    let jwt = localStorage.getItem('jwt');

    if (initialData) {
      updateSession(dispatch, {
        jwt,
        files: formData,
        initialPhotos: photos,
        id: initialData.id,
        uploadFiles,
        name,
        description,
        start_date: startDate,
        end_date: endDate,
        activity: selectedActivity.id,
        room_layout: selectedLayout.id,
        price: Number(price),
        duration: Number(duration),
        coach: selectedCoach.id,
        dates: dates.map((el) => ({ value: el })),
        frequency
      }).then((res) => {
        if (res === 'OK') {
          handleSave({
            message: 'Session updated Successfully',
            variant: 'success'
          });
        } else {
          handleSave({
            message: 'Problem while updating Session',
            variant: 'error'
          });
        }
      });
    } else {
      createSession(dispatch, {
        jwt,
        files: formData,
        uploadFiles,
        name,
        description,
        start_date: startDate,
        end_date: endDate,
        activity: selectedActivity.id,
        room_layout: selectedLayout.id,
        price: Number(price),
        duration: Number(duration),
        coach: selectedCoach.id,
        dates: dates.map((el) => ({ value: el })),
        frequency,
        active: true
      }).then((res) => {
        if (res === 'OK') {
          handleSave({
            message: 'Session created Successfully',
            variant: 'success'
          });
        } else {
          handleSave({
            message: 'Problem while creating Session',
            variant: 'error'
          });
        }
      });
    }
  };

  const addPhotos = (photos) => {
    setFiles(photos);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} fullScreen>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {initialData ? 'Edit Session' : 'Add Session'}
          </Typography>
          <Button autoFocus color="inherit" onClick={handleFormSubmit}>
            save
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent>
        {errorMsg && (
          <Alert severity="error" sx={{ my: 2 }}>
            {errorMsg.split('\n').map((line, index) => (
              <Typography key={index}>{line}</Typography>
            ))}
          </Alert>
        )}
        <Grid Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={6}>
            <Grid Grid container>
              <Grid Grid xs={6} sx={{ pr: 1 }}>
                <TextField autoFocus margin="dense" label="Name" type="text" fullWidth value={name} onChange={(e) => setName(e.target.value)} />
              </Grid>
              <Grid Grid xs={3} sx={{ pl: 1 }}>
                <TextField autoFocus margin="dense" label="Price" type="number" fullWidth value={price} onChange={(e) => setPrice(e.target.value)} />
              </Grid>
              <Grid Grid xs={3} sx={{ pl: 1 }}>
                <TextField autoFocus margin="dense" label="Duration" type="number" fullWidth value={duration} onChange={(e) => setDuration(e.target.value)} />
              </Grid>
            </Grid>
            <Grid Grid container sx={{ mt: 2 }}>
              <Grid Grid xs={6} sx={{ pr: 1 }}>
                <DateTimePickerValue value={startDate} setValue={setStartDate} title={'startDate'} />
              </Grid>
              <Grid Grid xs={6} sx={{ pl: 1 }}>
                <DateTimePickerValue value={endDate} setValue={setEndDate} title={'endDate'} />
              </Grid>
            </Grid>
            <TextField sx={{ mt: 2 }} margin="dense" label="Description" type="text" fullWidth value={description} multiline rows={4} onChange={(e) => setDescription(e.target.value)} />
            {startDate && <ScheduleManager defaultDateTime={startDate} setDates={setDates} defaultDates={defaultDates} frequency={frequency} setFrequency={setFrequency}></ScheduleManager>}
            <Box sx={{ mt: 2 }}>
              <p sx={{ my: 4 }}>Photos</p>
              {photos.length || files.length ? (
                <Grid container spacing={2}>
                  {photos.map((photo, index) => (
                    <Grid item xs={4} key={index}>
                      <Paper
                        sx={{
                          position: 'relative',
                          paddingTop: '100%',
                          overflow: 'hidden'
                        }}
                      >
                        <img
                          src={photo?.formats?.thumbnail?.url ? `${process.env.REACT_APP_SERVER_URL}${photo?.formats?.thumbnail?.url}` : ''}
                          alt={`Session Photo ${index}`}
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover'
                          }}
                        />
                        <IconButton
                          onClick={() => handleDeletePhoto(index)}
                          sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            color: 'red'
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Paper>
                    </Grid>
                  ))}
                  {Array.from(files).map((photo, index) => (
                    <Grid item xs={4} key={index}>
                      <Paper
                        sx={{
                          position: 'relative',
                          paddingTop: '100%',
                          overflow: 'hidden'
                        }}
                      >
                        <img
                          src={URL.createObjectURL(photo)}
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover'
                          }}
                        />
                        <IconButton
                          onClick={() => handleDeleteFile(index)}
                          sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            color: 'red'
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <p sx={{ my: 4 }}>No Photos</p>
              )}
            </Box>
            <Button component="label" role={undefined} variant="contained" tabIndex={-1} startIcon={<CloudUploadIcon />}>
              Upload file
              <VisuallyHiddenInput type="file" multiple onChange={(e) => addPhotos(e.target.files)} />
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              disablePortal
              id="activity"
              getOptionLabel={(option) => option.name}
              options={activities}
              value={selectedActivity}
              renderInput={(params) => <TextField margin="dense" {...params} label="Activitiy" />}
              onChange={(event, newValue) => {
                setSelectedActivity(newValue);
              }}
            />
            <Autocomplete
              disablePortal
              id="coach"
              getOptionLabel={(option) => `${option?.firstname} ${option?.lastname} ${option?.phone}`}
              options={coaches}
              value={selectedCoach}
              renderInput={(params) => <TextField margin="dense" {...params} label="Coach" />}
              onChange={(event, newValue) => {
                setSelectedCoach(newValue);
              }}
            />
            <Autocomplete
              disablePortal
              id="room"
              getOptionLabel={(option) => option.name}
              options={rooms}
              value={selectedRoom}
              renderInput={(params) => <TextField margin="dense" {...params} label="Room" />}
              onChange={(event, newValue) => {
                setSelectedRoom(newValue);
              }}
            />
            <Grid container spacing={3} sx={{ marginTop: 3 }}>
              {layouts?.length ? layouts.map((layout) => (
                <Grid item xs={4} sm={4} key={layout?.name}>
                  <LayoutCard
                    lines={lines}
                    layout={layout}
                    selectedLayout={selectedLayout}
                    setSelectedLayout={setSelectedLayout}
                    canDelete={false}
                    scale={0.2}
                  ></LayoutCard>
                </Grid>
              )) : ''}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default EditAddSessionModal;
