import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, Toolbar, Button, TextField, Paper, Box, IconButton, Grid, Alert, AppBar, Typography, Autocomplete, Select, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import LayoutCard from '../Rooms/LayoutCard';
import CloseIcon from '@mui/icons-material/Close';
import { fetchAllReservations, useReservationsContext, updateReservation } from '../../store/reservations';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const ReservationModal = ({ open, handleClose, handleSave, initialData }) => {
  const { state, dispatch } = useReservationsContext();
  const reservations = state.filtered || [];
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [selectedCircle, setSelectedCircle] = useState(null);
  const [filteredReservations, setFilteredReservations] = useState(null);
  const [status, setStatus] = useState('');
  const [circles, setCircles] = useState([]);
  const [requiredCircle, setRequiredCircle] = useState(false);

  useEffect(() => {
    if (initialData?.session?.id) {
      let jwt = localStorage.getItem('jwt');
      fetchAllReservations(dispatch, {
        jwt,
        id_session: initialData.session.id,
        datetime: initialData.datetime
      });
      setCircles([]);
      setStatus('');
      setSelectedCircle(null);
    }
  }, [initialData]);

  useEffect(() => {
    setFilteredReservations(reservations.filter((el) => el.status === 'pending'));
    setCircles(reservations.filter((el) => el.status === 'accepted').map((el) => ({ ...el.place, id_reservation: el.id })));
  }, [reservations]);

  useEffect(() => {
    setRequiredCircle(false);
  }, [selectedCircle]);

  const saveData = () => {
    let jwt = localStorage.getItem('jwt');

    if (selectedReservation) {
      updateReservation(dispatch, {
        jwt,
        id: initialData.id,
        status: 'canceled',
        place: { x: null, y: null, radius: null }
      }).then((res) => {
        if (res === 'OK') {
          let place = initialData.place;
          delete place.id;
          updateReservation(dispatch, {
            jwt,
            id: selectedReservation.id,
            status: 'accepted',
            place: initialData.place
          }).then((res) => {
            if (res === 'OK') {
              handleSave({
                message: 'Reservation updated Successfully',
                variant: 'success'
              });
            } else {
              handleSave({
                message: 'Problem while updating Reservation',
                variant: 'error'
              });
            }
            handleClose();
          });
        } else {
          handleSave({
            message: 'Problem while updating Reservation',
            variant: 'error'
          });
        }
      });
    } else {
      let place = { x: null, y: null, radius: null };
      if (status === 'accepted') {
        if (!selectedCircle) {
          setRequiredCircle(true);
          return;
        } else {
          place = {
            x: selectedCircle.x,
            y: selectedCircle.y,
            radius: selectedCircle.radius
          };
        }
      }

      updateReservation(dispatch, {
        jwt,
        id: initialData.id,
        status,
        place
      }).then((res) => {
        if (res === 'OK') {
          if (selectedCircle?.id_reservation) {
            updateReservation(dispatch, {
              jwt,
              id: selectedCircle.id_reservation,
              status: 'canceled',
              place: { x: null, y: null, radius: null }
            }).then((res) => {
              if (res === 'OK') {
                handleSave({
                  message: 'Reservation updated Successfully',
                  variant: 'success'
                });
              } else {
                handleSave({
                  message: 'Problem while updating Reservation',
                  variant: 'error'
                });
              }
              handleClose();
            });
          } else {
            handleSave({
              message: 'Reservation updated Successfully',
              variant: 'success'
            });
            handleClose();
          }
        } else {
          handleSave({
            message: 'Problem while updating Reservation',
            variant: 'error'
          });
          handleClose();
        }
      });
    }
  };
  const changeSelectedReservation = (value) => {
    setSelectedReservation(value);
    setStatus('');
  };

  const changeStatus = (value) => {
    setSelectedReservation(null);
    setStatus(value);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullScreen>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Reservation Modal
          </Typography>
          <Button autoFocus color="inherit" onClick={() => saveData()}>
            save
          </Button>
        </Toolbar>
      </AppBar>
      {initialData && (
        <DialogContent>
          <Typography variant="h3" component="h3">
            {initialData.session.name}
          </Typography>
          <Grid Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={6}>
              <Typography variant="h5" component="h5" my={2}>
                Coach :
              </Typography>
              <Paper
                px={4}
                sx={{
                  position: 'relative',
                  border: '1px solid white',
                  marginBottom: '10px',
                  height: '200px',
                  overflow: 'hidden',
                  padding: '10px'
                }}
              >
                <Grid Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={6} display={'flex'} justifyContent={'center'}>
                    <img
                      src={initialData?.session?.coach?.photo?.formats?.thumbnail?.url ? `${process.env.REACT_APP_SERVER_URL}${initialData.session.coach.photo?.formats?.thumbnail?.url}` : ''}
                      alt={`Coach Photo`}
                      style={{
                        width: '150px',
                        height: 'auto',
                        objectFit: 'cover'
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="p" component="p">
                      {initialData.session.coach.firstname} {initialData.session.coach.lastname}
                    </Typography>
                    <Typography variant="p" component="p">
                      {initialData.session.coach.phone}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
              <Typography variant="h5" component="h5" my={2}>
                User :
              </Typography>
              <Paper
                px={4}
                sx={{
                  position: 'relative',
                  border: '1px solid white',
                  marginBottom: '10px',
                  height: '200px',
                  overflow: 'hidden',
                  padding: '10px'
                }}
              >
                <Grid Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={6} display={'flex'} justifyContent={'center'}>
                    <img
                      src={
                        initialData?.user_account?.photo?.formats?.thumbnail?.url ? `${process.env.REACT_APP_SERVER_URL}${initialData.user_account.photo?.formats?.thumbnail?.url}` : '/avatar.svg.png'
                      }
                      alt={`Coach Photo`}
                      style={{
                        width: '150px',
                        height: 'auto',
                        objectFit: 'cover'
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="p" component="p">
                      {initialData.user_account.firstname} {initialData.user_account.lastname}
                    </Typography>
                    <Typography variant="p" component="p">
                      {initialData.user_account.phone}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
              {initialData.status === 'accepted' && (
                <Paper
                  px={4}
                  sx={{
                    position: 'relative',
                    border: '1px solid white',
                    marginBottom: '10px',
                    height: '200px',
                    overflow: 'hidden',
                    padding: '10px'
                  }}
                >
                  <Typography variant="h5" component="h5" my={2}>
                    Change user:{' '}
                  </Typography>
                  <Alert severity="error">(this reservation will be canceled for current user)</Alert>
                  <Autocomplete
                    id="activity"
                    getOptionLabel={(option) => `${option.user_account.firstname} ${option.user_account.lastname}`}
                    options={filteredReservations}
                    value={selectedReservation}
                    renderInput={(params) => <TextField margin="dense" {...params} label="Pending Reservation" />}
                    onChange={(event, newValue) => {
                      changeSelectedReservation(newValue);
                    }}
                  />
                </Paper>
              )}
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" component="h6">
                Activitiy: {initialData?.session?.activity.name}
              </Typography>
              <Typography variant="h6" component="h6">
                Status: {initialData?.status}
              </Typography>
              <Typography variant="h6" component="h6">
                Room: {initialData?.session?.room_layout.room.name}
              </Typography>
              <Typography variant="h6" component="h6">
                Room Layout: {initialData?.session?.room_layout.name}
              </Typography>
              {initialData?.session?.room_layout?.room?.lines && (
                <LayoutCard
                  activeCircle={initialData?.place}
                  disable={true}
                  lines={initialData?.session?.room_layout?.room?.lines.map((l) => ({
                    length: l.length,
                    points: [l.start_x, l.start_y, l.end_x, l.end_y]
                  }))}
                  layout={initialData?.session?.room_layout}
                  canDelete={false}
                  scale={0.2}
                ></LayoutCard>
              )}
              <Paper
                px={4}
                sx={{
                  position: 'relative',
                  border: '1px solid white',
                  marginBottom: '10px',
                  overflow: 'hidden',
                  padding: '10px'
                }}
              >
                <Typography variant="h5" component="h5" my={2}>
                  Change status:{' '}
                </Typography>
                <Select value={status} onChange={(e) => changeStatus(e.target.value)} label="Status" sx={{ width: '100%' }}>
                  {initialData.status !== 'pending' && <MenuItem value="pending">Pending</MenuItem>}
                  {initialData.status !== 'accepted' && <MenuItem value="accepted">Accepted</MenuItem>}
                  {initialData.status !== 'canceled' && <MenuItem value="canceled">Canceled</MenuItem>}
                </Select>
                {status === 'accepted' && initialData.status !== 'accepted' && initialData?.session?.room_layout?.room?.lines && (
                  <>
                    {circles?.length && selectedCircle && circles.find((el) => el.x === selectedCircle.x && el.y === selectedCircle.y) ? (
                      <Alert severity="error">
                        this place is already reserved
                        <br />
                        and it will be canceled if you save
                      </Alert>
                    ) : (
                      ''
                    )}
                    {requiredCircle ? <Alert severity="error">Select the placement</Alert> : ''}
                    <LayoutCard
                      activeCircle={initialData?.place}
                      disable={true}
                      lines={initialData?.session?.room_layout?.room?.lines.map((l) => ({
                        length: l.length,
                        points: [l.start_x, l.start_y, l.end_x, l.end_y]
                      }))}
                      layout={initialData?.session?.room_layout}
                      canDelete={false}
                      scale={0.5}
                      reservedCircles={circles}
                      selectedCircle={selectedCircle}
                      setSelectedCircle={setSelectedCircle}
                    ></LayoutCard>
                  </>
                )}
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default ReservationModal;
