import React, { useEffect } from 'react';
import Sidebar from '../components/Layout/Sidebar';
import Navbar from '../components/Layout/Navbar';
import { getMe, useUserContext } from '../store/user';
import { useNavigate } from 'react-router-dom';
import { Box, CssBaseline, Toolbar } from '@mui/material';

const Layout = ({ children }) => {
  const { dispatch } = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    let jwt = localStorage.getItem('jwt');
    getMe(dispatch, { jwt }).then((res) => {
      if (!res) {
        navigate('/login');
      }
    });
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        {children}
      </Box>
      <Navbar />
    </Box>
  );
};

export default Layout;
