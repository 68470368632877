import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, Button, TextField, Typography, Paper, Box, IconButton, Grid, Alert, AppBar, Toolbar, CardContent, Card, Snackbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { updateRoom, createRoom, useRoomsContext, deleteRoomLayout, createRoomLayout, updateRoomLayout } from '../../store/rooms';
import CloseIcon from '@mui/icons-material/Close';
import DrawingLayout from './DrawingLayout';
import LayoutCard from './LayoutCard';
import DeleteConfirmationModal from '../common/DeleteConfirmationModal';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const EditAddRoomLayoutModal = ({ open, handleClose, handleSave, initialData, lines, refetchData }) => {
  const [radius, setRadius] = useState(20);
  const [roomId, setRoomId] = useState(null);
  const [circles, setCircles] = useState([]);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [selectedLayout, setSelectedLayout] = useState(null);
  const [selectedLayoutToDelete, setSelectedLayoutToDelete] = useState(null);
  const [layouts, setLayouts] = useState('');
  const [step, setStep] = useState(0);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { dispatch } = useRoomsContext();
  const [openSnack, setOpenSnack] = useState(false);
  const [msg, setMsg] = useState(false);
  const [severity, setSeverity] = useState(false);

  useEffect(() => {
    if (initialData?.room_layouts?.length) {
      setLayouts(initialData.room_layouts);
    } else {
      setLayouts([]);
    }
    setRoomId(initialData?.id);
  }, [initialData]);

  const deleteLayout = (layout) => {
    setSelectedLayoutToDelete(layout);
    setDeleteModalOpen(true);
  };

  const createNewLayout = () => {
    setStep(1);
    setName('');
    setDescription('');
    setCircles([]);
    setRadius(20);
  };

  useEffect(() => {
    if (selectedLayout) {
      setName(selectedLayout.name);
      setDescription(selectedLayout.description);
      setCircles(selectedLayout.place);
      setStep(1);
      if (selectedLayout?.place?.length) {
        setRadius(selectedLayout?.place[0].radius);
      }
    }
  }, [selectedLayout]);

  const closeModal = () => {
    if (step === 1) {
      setStep(0);
    } else {
      handleClose();
    }
  };
  const saveModal = () => {
    if (step === 0) {
      handleClose();
      return;
    }
    if (!name) {
      setErrorMsg('Add a Name');
      return;
    }
    if (!circles?.length) {
      setErrorMsg('at Least one circle');
      return;
    }
    let jwt = localStorage.getItem('jwt');
    setSelectedLayout(null);
    if (selectedLayout) {
      updateRoomLayout(dispatch, {
        jwt,
        name,
        description,
        id: selectedLayout.id,
        place: circles,
        room: roomId
      }).then((res) => {
        if (res === 'OK') {
          handleSave({
            message: 'Room Layout updated Successfully',
            variant: 'success'
          });
        } else {
          handleSave({
            message: 'Problem while updating Room Layout',
            variant: 'error'
          });
        }
        setStep(0);
      });
    } else {
      createRoomLayout(dispatch, {
        jwt,
        name,
        description,
        place: circles,
        room: roomId
      }).then((res) => {
        if (res === 'OK') {
          handleSave({
            message: 'Room Layout created Successfully',
            variant: 'success'
          });
        } else {
          handleSave({
            message: 'Problem while creating Room Layout',
            variant: 'error'
          });
        }
        setStep(0);
      });
    }
  };
  const handleDelete = () => {
    let jwt = localStorage.getItem('jwt');
    deleteRoomLayout(dispatch, { jwt, id: selectedLayoutToDelete.id }).then((res) => {
      setOpenSnack(true);
      if (res === 'OK') {
        const filteredLayouts = layouts.filter((l) => l.id !== selectedLayoutToDelete.id);
        setLayouts([...filteredLayouts]);
        setMsg('Room deleted Successfully');
        setSeverity('success');
        refetchData();
      } else {
        setMsg('Problem while deleting Room');
        setSeverity('error');
      }
      setDeleteModalOpen(false);
    });
  };

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  return (
    <>
      <DeleteConfirmationModal open={deleteModalOpen} handleClose={() => setDeleteModalOpen(false)} handleConfirm={() => handleDelete()} title="Are you sure you want to delete this room?" />

      <Dialog open={open} onClose={handleClose} fullScreen>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={closeModal} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {'Room Layout'}
            </Typography>
            <Button autoFocus color="inherit" onClick={saveModal}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          {step === 0 ? (
            <Grid container spacing={3} sx={{ marginTop: 3 }}>
              {layouts?.length ? layouts.map((layout) => (
                <Grid item xs={12} sm={6} md={4} key={layout.name}>
                  <LayoutCard
                    lines={lines}
                    layout={layout}
                    selectedLayout={selectedLayout}
                    setStep={setStep}
                    setSelectedLayout={setSelectedLayout}
                    deleteLayout={deleteLayout}
                    canDelete={true}
                    scale={0.2}
                  ></LayoutCard>
                </Grid>
              )) : ''}
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  sx={{
                    position: 'relative',
                    height: '200px',
                    border: '1px solid white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '10px'
                  }}
                  onClick={() => createNewLayout()}
                >
                  <Typography variant="h6">+ New layout</Typography>
                </Paper>
              </Grid>
            </Grid>
          ) : (
            <DrawingLayout
              lines={lines}
              circles={circles}
              setCircles={setCircles}
              radius={radius}
              setRadius={setRadius}
              errorMsg={errorMsg}
              setErrorMsg={setErrorMsg}
              setName={setName}
              setDescription={setDescription}
              name={name}
              description={description}
            />
          )}
        </DialogContent>
      </Dialog>
      <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severity} variant="filled" sx={{ width: '100%' }}>
          {msg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EditAddRoomLayoutModal;
