import React from 'react';
import { Container, Grid, Card, CardContent, Typography, CardActionArea } from '@mui/material';
import SellIcon from '@mui/icons-material/Sell';
import LocalActivity from '@mui/icons-material/LocalActivity';
import Person from '@mui/icons-material/Person';
import AirplaneTicket from '@mui/icons-material/AirplaneTicket';
import Room from '@mui/icons-material/Room';
import Book from '@mui/icons-material/Book';
import { NavLink } from 'react-router-dom';

const sections = [
  { text: 'Activities', icon: <LocalActivity />, to: '/activities' },
  { text: 'Coaches', icon: <Person />, to: '/coaches' },
  { text: 'Reservations', icon: <AirplaneTicket />, to: '/reservations' },
  { text: 'Rooms', icon: <Room />, to: '/rooms' },
  { text: 'Sessions', icon: <Book />, to: '/sessions' },
  { text: 'Special Offer', icon: <SellIcon />, to: '/special-offer' }

];

const HomePage = () => {
  return (
    <Grid container spacing={3} sx={{ marginTop: 3 }}>
      {sections.map((section) => (
        <Grid item xs={12} sm={6} md={4} key={section.text}>
          <Card
            sx={{
              height: '200px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CardActionArea
              component={NavLink}
              to={section.to}
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {section.icon}
                <Typography variant="h6" sx={{ marginTop: 2 }}>
                  {section.text}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default HomePage;
