import React, { createContext, useReducer, useContext } from 'react';
import axios from 'axios';

const UserContext = createContext();

const userReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        isAuth: true,
        user: action.payload.user,
        jwt: action.payload.jwt
      };
    case 'LOGOUT':
      return { ...state, isAuth: false, user: null, jwt: '' };
    case 'SET_ALL':
      return { ...state, all: action.payload };
    case 'SET_PAGINATION':
      return { ...state, pagination: action.payload };

    default:
      return state;
  }
};
const initialState = {
  isAuth: false,
  user: null,
  jwt: '',
  all: [],
  pagination: null
};

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, { ...initialState });

  return <UserContext.Provider value={{ state, dispatch }}>{children}</UserContext.Provider>;
};

export const useUserContext = () => useContext(UserContext);

export const requestLogin = async (dispatch, { identifier, password }) => {
  try {
    let result = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/auth/local?populate[user_account][populate]=*`, {
      identifier,
      password
    });
    if (result?.data?.jwt) {
      const { user, jwt } = result.data;
      dispatch({ type: 'LOGIN', payload: { user, jwt } });
      localStorage.setItem('user', user);
      localStorage.setItem('jwt', jwt);
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const getMe = async (dispatch, { jwt }) => {
  try {
    let result = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/users/me`, {
      headers: {
        Authorization: 'Bearer ' + jwt
      }
    });
    if (result?.data) {
      dispatch({ type: 'LOGIN', payload: { user: result.data, jwt } });
      localStorage.setItem('user', result.data);
      localStorage.setItem('jwt', jwt);
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const requestLogout = async (dispatch) => {
  dispatch({ type: 'LOGOUT' });
  localStorage.removeItem('user');
  localStorage.removeItem('jwt');
};

export const fetchAllCoaches = async (dispatch, { jwt, page, pageSize }) => {
  try {
    let request = `${process.env.REACT_APP_SERVER_URL}/api/user-accounts?populate=*&sort[1]=id:desc&filters[$and][0][type]=coach&filters[$and][1][active]=true`;

    if (page) {
      request += `&pagination[page]=${page}`;
    }
    if (pageSize) {
      request += `&pagination[pageSize]=${pageSize}`;
    }

    let result = await axios.get(request, {
      headers: {
        Authorization: 'Bearer ' + jwt
      }
    });
    if (result?.data?.data?.length) {
      dispatch({ type: 'SET_ALL', payload: result?.data?.data });
      dispatch({
        type: 'SET_PAGINATION',
        payload: result?.data?.meta.pagination
      });
    }
  } catch (error) {
    console.log({ error });
    return false;
  }
};

export const createCoach = async (dispatch, { jwt, files, firstname, lastname, phone, email, description, uploadFiles }) => {
  try {
    let result = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/auth/local/register`, {
      username: Date.now().toString(),
      email,
      password: 'password'
    });
    if (result?.data?.user?.id) {
      let photos = [];
      if (uploadFiles) {
        let uploadFiles = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/upload/`, files, { headers: { Authorization: 'Bearer ' + jwt } });
        if (uploadFiles?.data?.length) {
          photos = uploadFiles?.data?.map((f) => f.id);
        }
      }
      let res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/user-accounts`,
        {
          data: {
            firstname,
            lastname,
            phone,
            description,
            photos,
            user: result?.data?.user?.id,
            type: 'coach',
            active: true
          }
        },
        {
          headers: { Authorization: 'Bearer ' + jwt }
        }
      );
      if (res?.data) {
        return 'OK';
      } else {
        return 'KO';
      }
    } else {
      return 'KO';
    }
  } catch (error) {
    console.log({ error });
    return 'KO';
  }
};
export const updateCoach = async (dispatch, { firstname, lastname, phone, description, files, jwt, initialPhotos, id, uploadFiles }) => {
  try {
    let photos = [];
    if (uploadFiles) {
      let uploadFiles = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/upload/`, files, { headers: { Authorization: 'Bearer ' + jwt } });
      if (uploadFiles?.data?.length) photos = uploadFiles?.data?.map((f) => f.id);
    }
    if (initialPhotos) {
      photos = [...photos, ...initialPhotos.map((el) => el.id)];
    }
    let result = await axios.put(
      `${process.env.REACT_APP_SERVER_URL}/api/user-accounts/${id}`,
      {
        data: {
          firstname,
          lastname,
          phone,
          description,
          photos
        }
      },
      {
        headers: { Authorization: 'Bearer ' + jwt }
      }
    );

    if (result?.data) {
      return 'OK';
    } else {
      return 'KO';
    }
  } catch (error) {
    console.log({ error });
    return 'KO';
  }
};
export const deleteCoach = async (dispatch, { id, id_user, jwt }) => {
  try {
    let result = await axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/user-accounts/${id}`, {
      headers: { Authorization: 'Bearer ' + jwt }
    });
    let res = await axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/users/${id_user}`, {
      headers: { Authorization: 'Bearer ' + jwt }
    });

    if (result?.data && res?.data) {
      return 'OK';
    } else {
      return 'KO';
    }
  } catch (error) {
    console.log({ error });
    return 'KO';
  }
};
