import React, { useState, useEffect } from 'react';
import { Stage, Layer, Circle, Line, Text } from 'react-konva';
import { Grid, TextField, Button, Alert } from '@mui/material';

const DrawingLayout = ({ lines, circles, setCircles, errorMsg, setErrorMsg, setName, setDescription, name, description, radius, setRadius }) => {
  const [selectedCircleIndex, setSelectedCircleIndex] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [distances, setDistances] = useState({ xDistance: 0, yDistance: 0 });
  const [referencePoints, setReferencePoints] = useState({
    minX: 0,
    minY: 0,
    maxX: 0,
    maxY: 0
  });

  useEffect(() => {
    calculateReferencePoints();
  }, [lines]);

  const calculateReferencePoints = () => {
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    lines.forEach((line) => {
      const [x1, y1, x2, y2] = line.points;

      if (x1 < minX) minX = x1;
      if (x2 < minX) minX = x2;

      if (x1 > maxX) maxX = x1;
      if (x2 > maxX) maxX = x2;

      if (y1 < minY) minY = y1;
      if (y2 < minY) minY = y2;

      if (y1 > maxY) maxY = y1;
      if (y2 > maxY) maxY = y2;
    });

    setReferencePoints({ minX, minY, maxX, maxY });
  };

  const isInsideLines = (x, y) => {
    let wn = 0;

    for (let i = 0; i < lines.length; i++) {
      const [x1, y1, x2, y2] = lines[i].points;

      if (y1 <= y) {
        if (y2 > y && isLeft(x1, y1, x2, y2, x, y) > 0) {
          wn++;
        }
      } else {
        if (y2 <= y && isLeft(x1, y1, x2, y2, x, y) < 0) {
          wn--;
        }
      }
    }

    return wn !== 0;
  };

  const isLeft = (x0, y0, x1, y1, x2, y2) => {
    return (x1 - x0) * (y2 - y0) - (x2 - x0) * (y1 - y0);
  };

  const handleDeleteCircle = () => {
    if (selectedCircleIndex !== null) {
      const updatedCircles = circles.filter((circle, index) => index !== selectedCircleIndex);
      setCircles(updatedCircles);
      setSelectedCircleIndex(null);
    }
  };

  const handleRadiusChange = (newRadius) => {
    const updatedCircles = circles.map((circle) => {
      if (isInsideLines(circle.x, circle.y)) {
        return { ...circle, radius: newRadius };
      }
      return circle;
    });
    setCircles(updatedCircles);
    setRadius(newRadius);
  };

  const handleContainerClick = (e) => {
    const { offsetX, offsetY } = e.evt;
    const clickedCircleIndex = circles.findIndex((circle) => {
      const dx = circle.x - offsetX;
      const dy = circle.y - offsetY;
      return dx * dx + dy * dy <= circle.radius * circle.radius;
    });

    if (clickedCircleIndex === -1 && isInsideLines(offsetX, offsetY)) {
      setCircles([
        ...circles,
        {
          x: Math.round(offsetX / 10) * 10,
          y: Math.round(offsetY / 10) * 10,
          radius
        }
      ]);
      setSelectedCircleIndex(null);
    } else if (clickedCircleIndex !== -1) {
      setSelectedCircleIndex(clickedCircleIndex);
    }
  };

  const handleMouseMove = (e) => {
    const { offsetX, offsetY } = e.evt;
    setMousePosition({ x: offsetX, y: offsetY });

    const xDistance = Math.abs(offsetX - referencePoints.minX);
    const yDistance = Math.abs(offsetY - referencePoints.minY);

    setDistances({ xDistance, yDistance });
  };

  const handleXChange = (e) => {
    const newX = parseInt(e.target.value);
    const updatedCircles = circles.map((circle, index) => {
      if (index === selectedCircleIndex) {
        return { ...circle, x: newX };
      }
      return circle;
    });
    setCircles(updatedCircles);
  };

  const handleYChange = (e) => {
    const newY = parseInt(e.target.value);
    const updatedCircles = circles.map((circle, index) => {
      if (index === selectedCircleIndex) {
        return { ...circle, y: newY };
      }
      return circle;
    });
    setCircles(updatedCircles);
  };

  const adjustCircles = () => {
    const adjustedCircles = [...circles];
    const threshold = 20;

    for (let i = 0; i < adjustedCircles.length; i++) {
      for (let j = i + 1; j < adjustedCircles.length; j++) {
        if (Math.abs(adjustedCircles[i].x - adjustedCircles[j].x) < threshold) {
          adjustedCircles[j].x = adjustedCircles[i].x;
        }
      }
    }

    for (let i = 0; i < adjustedCircles.length; i++) {
      for (let j = i + 1; j < adjustedCircles.length; j++) {
        if (Math.abs(adjustedCircles[i].y - adjustedCircles[j].y) < threshold) {
          adjustedCircles[j].y = adjustedCircles[i].y;
        }
      }
    }

    setCircles(adjustedCircles);
  };

  const adjustedX = (x) => x - referencePoints.minX;
  const adjustedY = (y) => y - referencePoints.minY;

  const stageWidth = referencePoints.maxX - referencePoints.minX;
  const stageHeight = referencePoints.maxY - referencePoints.minY;

  return (
    <Grid container>
      <Grid item xs={9} style={{ display: 'flex', justifyContent: 'center' }}>
        <Stage width={stageWidth} height={stageHeight} onClick={handleContainerClick} onMouseMove={handleMouseMove}>
          <Layer>
            {lines.map((line, index) => (
              <React.Fragment key={index}>
                <Line points={line.points.map((point, i) => (i % 2 === 0 ? adjustedX(point) : adjustedY(point)))} stroke="black" />
                <Text text={`${(line.length / 100).toFixed(2)}`} x={adjustedX((line.points[0] + line.points[2]) / 2)} y={adjustedY((line.points[1] + line.points[3]) / 2)} />
              </React.Fragment>
            ))}
            {circles.map((circle, index) => (
              <Circle
                key={index}
                x={adjustedX(circle.x)}
                y={adjustedY(circle.y)}
                radius={circle.radius}
                stroke={selectedCircleIndex === index ? 'red' : 'black'}
                strokeWidth={2}
                onClick={() => setSelectedCircleIndex(index)}
              />
            ))}
            <Line points={[adjustedX(referencePoints.minX), mousePosition.y, mousePosition.x, mousePosition.y]} stroke="blue" />
            <Line points={[mousePosition.x, adjustedY(referencePoints.minY), mousePosition.x, mousePosition.y]} stroke="blue" />
            <Text
              text={`x: ${((Math.round(distances.xDistance / 10) * 10) / 100).toFixed(2)}, y: ${((Math.round(distances.yDistance / 10) * 10) / 100).toFixed(2)}`}
              x={mousePosition.x < 100 ? mousePosition.x : mousePosition.x - 100}
              y={mousePosition.y < 100 ? mousePosition.y : mousePosition.y - 20}
              fill="blue"
            />
          </Layer>
        </Stage>
      </Grid>
      <Grid item xs={3}>
        <TextField label="Circle Radius" type="number" value={radius} onChange={(e) => handleRadiusChange(parseInt(e.target.value))} fullWidth sx={{ marginBottom: '10px' }} />
        {selectedCircleIndex !== null && (
          <>
            <TextField label="X Coordinate" type="number" value={circles[selectedCircleIndex].x} onChange={handleXChange} fullWidth sx={{ marginBottom: '10px' }} />
            <TextField label="Y Coordinate" type="number" value={circles[selectedCircleIndex].y} onChange={handleYChange} fullWidth sx={{ marginBottom: '10px' }} />
            <Button onClick={handleDeleteCircle} sx={{ marginBottom: '10px' }} fullWidth>
              Delete
            </Button>
          </>
        )}
        <Button onClick={adjustCircles} fullWidth sx={{ marginBottom: '10px' }}>
          Adjust
        </Button>
        <TextField
          autoFocus
          margin="dense"
          label="Name"
          type="text"
          fullWidth
          value={name}
          onChange={(e) => {
            setErrorMsg('');
            setName(e.target.value);
          }}
        />
        <TextField margin="dense" label="Description" type="text" fullWidth value={description} multiline rows={4} onChange={(e) => setDescription(e.target.value)} />
        {errorMsg && (
          <Alert severity="error" sx={{ my: 2 }}>
            {errorMsg}
          </Alert>
        )}
      </Grid>
    </Grid>
  );
};

export default DrawingLayout;
