import React, { useState, useRef, useEffect } from 'react';
import { Stage, Layer, Line, Text, Rect } from 'react-konva';

const DrawingBoard = ({ lines, setLines }) => {
  const [currentLine, setCurrentLine] = useState(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [ctrlPressed, setCtrlPressed] = useState(false);
  const [lineLength, setLineLength] = useState(0);
  const stageRef = useRef();

  const handleMouseDown = (e) => {
    if (e.evt.button === 2) {
      e.evt.preventDefault();
      if (lines.length > 0) {
        setLines((prevLines) => prevLines.slice(0, -1));
      }
    } else if (!isDrawing) {
      const { x, y } = e.target.getStage().getPointerPosition();
      const startPoint = lines.length > 0 ? [lines[lines.length - 1].points[2], lines[lines.length - 1].points[3]] : [x, y];
      const adjustedLength = Math.round(lineLength / 10) * 10;
      setCurrentLine({ points: [...startPoint, x, y], length: adjustedLength });
      setIsDrawing(true);
    }
  };

  const handleMouseMove = (e) => {
    if (!isDrawing) return;
    const { x, y } = e.target.getStage().getPointerPosition();
    const newPoints = [...currentLine.points];
    if (ctrlPressed) {
      if (Math.abs(x - newPoints[0]) > Math.abs(y - newPoints[1])) {
        newPoints[2] = x;
        newPoints[3] = newPoints[1];
      } else {
        newPoints[2] = newPoints[0];
        newPoints[3] = y;
      }
    } else {
      newPoints[2] = x;
      newPoints[3] = y;
    }
    const length = Math.sqrt(Math.pow(newPoints[2] - newPoints[0], 2) + Math.pow(newPoints[3] - newPoints[1], 2));
    const roundedLength = Math.round(length / 10) * 10;
    setCurrentLine({ points: newPoints, length: roundedLength });
    setLineLength(roundedLength);
  };

  const handleMouseUp = () => {
    if (isDrawing) {
      const adjustedLength = Math.round(lineLength / 10) * 10;
      let newPoints = [...currentLine.points];
      if (Math.abs(newPoints[2] - newPoints[0]) > Math.abs(newPoints[3] - newPoints[1])) {
        newPoints[2] = newPoints[0] + Math.sign(newPoints[2] - newPoints[0]) * adjustedLength;
      } else {
        newPoints[3] = newPoints[1] + Math.sign(newPoints[3] - newPoints[1]) * adjustedLength;
      }
      newPoints = newPoints.map((el) => Math.round(el / 10) * 10);
      setLines([...lines, { points: newPoints, length: adjustedLength }]);
      setCurrentLine(null);
      setIsDrawing(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Control') {
      setCtrlPressed(true);
    }
  };

  const handleKeyUp = (e) => {
    if (e.key === 'Control') {
      setCtrlPressed(false);
    }
  };

  return (
    <div onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} tabIndex="0" style={{ padding: '20px', border: '1px solid black' }}>
      <Stage width={1200} height={800} onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp} ref={stageRef}>
        <Layer>
          {lines.map((line, i) => (
            <React.Fragment key={i}>
              <Line points={line.points} stroke="black" />
              <Text text={`Length: ${(line.length / 100).toFixed(2)}`} x={(line.points[0] + line.points[2]) / 2} y={(line.points[1] + line.points[3]) / 2} />
            </React.Fragment>
          ))}
          {currentLine && (
            <React.Fragment>
              <Line points={currentLine.points} stroke="red" />
              <Text text={`Length: ${(currentLine.length / 100).toFixed(2)}`} x={(currentLine.points[0] + currentLine.points[2]) / 2} y={(currentLine.points[1] + currentLine.points[3]) / 2} />
            </React.Fragment>
          )}
        </Layer>
      </Stage>
    </div>
  );
};

export default DrawingBoard;
