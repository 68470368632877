import { IconButton, Paper, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Stage, Layer, Circle, Line, Text } from 'react-konva';

const LayoutCard = ({ layout, selectedLayout, setSelectedLayout, deleteLayout, setStep, lines, canDelete, disable, activeCircle, scale, reservedCircles, selectedCircle, setSelectedCircle }) => {
  const calculateReferencePoints = () => {
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    lines.forEach((line) => {
      const [x1, y1, x2, y2] = line.points;

      if (x1 < minX) minX = x1;
      if (x2 < minX) minX = x2;

      if (x1 > maxX) maxX = x1;
      if (x2 > maxX) maxX = x2;

      if (y1 < minY) minY = y1;
      if (y2 < minY) minY = y2;

      if (y1 > maxY) maxY = y1;
      if (y2 > maxY) maxY = y2;
    });

    return { minX, minY, maxX, maxY };
  };

  const referencePoints = calculateReferencePoints();

  const adjustedX = (x) => x - referencePoints.minX;
  const adjustedY = (y) => y - referencePoints.minY;

  const stageWidth = referencePoints.maxX - referencePoints.minX;
  const stageHeight = referencePoints.maxY - referencePoints.minY;
  const offsetX = (stageWidth / 2) * (1 - scale);
  const offsetY = (stageHeight / 2) * (1 - scale);

  const generateStroke = (circle) => {
    if (activeCircle && activeCircle.x === circle.x && activeCircle.y === circle.y) {
      return 'red';
    }
    if (reservedCircles?.length && reservedCircles.find((el) => el.x === circle.x && el.y === circle.y)) {
      return 'red';
    }
    return 'black';
  };

  const selectLayout = () => {
    if (disable) {
      return;
    }
    setSelectedLayout(layout);
    if (canDelete) {
      setStep(1);
    }
  };

  const selectCircle = (circle) => {
    let id_reservation = null;
    if (reservedCircles?.length && reservedCircles.find((el) => el.x === circle.x && el.y === circle.y)) {
      id_reservation = reservedCircles.find((el) => el.x === circle.x && el.y === circle.y).id_reservation;
    }
    setSelectedCircle({ ...circle, id_reservation });
  };

  return (
    <>
      <Paper
        sx={{
          position: 'relative',
          height: scale === 0.5 ? '400px' : '200px',
          border: selectedLayout && layout.id === selectedLayout?.id ? '1px solid green' : '1px solid white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '10px',
          overflow: 'hidden'
        }}
        onClick={() => selectLayout()}
      >
        <div style={{ position: 'absolute', overflow: 'hidden' }}>
          {lines && lines.length && layout.place && layout?.place?.length && (
            <Stage width={stageWidth} height={stageHeight} scale={{ x: scale, y: scale }} x={offsetX} y={offsetY}>
              <Layer>
                {lines.map((line, index) => (
                  <React.Fragment key={index}>
                    <Line points={line.points.map((point, i) => (i % 2 === 0 ? adjustedX(point) : adjustedY(point)))} stroke="black" />
                  </React.Fragment>
                ))}
                {layout.place.map((circle, index) => (
                  <Circle
                    key={index}
                    x={adjustedX(circle.x)}
                    y={adjustedY(circle.y)}
                    radius={circle.radius}
                    stroke={selectedCircle && selectedCircle.x === circle.x && selectedCircle.y === circle.y ? 'green' : generateStroke(circle)}
                    fill={generateStroke(circle) === 'red' ? 'red' : 'transparent'}
                    strokeWidth={10 * scale}
                    onClick={() => selectCircle(circle)}
                  />
                ))}
              </Layer>
            </Stage>
          )}
        </div>

        {canDelete && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              deleteLayout(layout);
            }}
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              color: 'red',
              zIndex: 999
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Paper>
      {!disable && <Typography variant="p">{layout.name}</Typography>}
    </>
  );
};

export default LayoutCard;
