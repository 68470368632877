import DeleteConfirmationModal from '../components/common/DeleteConfirmationModal';
import EditAddCoachModal from '../components/Coaches/EditAddCoachModal';
import React, { useEffect, useState } from 'react';
import { Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Paper, Box, Pagination, Alert, Snackbar } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { fetchAllCoaches, useUserContext, deleteCoach } from '../store/user';

const Coaches = () => {
  const { state, dispatch } = useUserContext();
  const coaches = state.all || [];
  const pagination = state.pagination;
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editAddModalOpen, setEditAddModalOpen] = useState(false);
  const [currentCoach, setCurrentCoach] = useState(null);
  const [coachToDelete, setCoachToDelete] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState(false);
  const [severity, setSeverity] = useState(false);

  const pageSize = 10;

  useEffect(() => {
    let jwt = localStorage.getItem('jwt');
    fetchAllCoaches(dispatch, { jwt, pageSize, page: activePage });
  }, [activePage]);

  const handleEdit = (coach) => {
    setCurrentCoach(coach);
    setEditAddModalOpen(true);
  };

  const handleDelete = ({ id, id_user }) => {
    let jwt = localStorage.getItem('jwt');

    deleteCoach(dispatch, { jwt, id, id_user }).then((res) => {
      setOpen(true);
      if (res === 'OK') {
        fetchAllCoaches(dispatch, { jwt, id });
        setMsg('Coach deleted Successfully');
        setSeverity('success');
      } else {
        setMsg('Problem while deleting Coach');
        setSeverity('error');
      }
    });

    setDeleteModalOpen(false);
  };

  const handleSave = ({ message, variant }) => {
    setOpen(true);
    setMsg(message);
    setSeverity(variant);
    setEditAddModalOpen(false);
    let jwt = localStorage.getItem('jwt');
    fetchAllCoaches(dispatch, { jwt, pageSize, page: 1 });
  };

  const handlePageChange = (event, page) => {
    setActivePage(page);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3
        }}
      >
        <Typography variant="h4">Coaches</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setCurrentCoach(null);
            setEditAddModalOpen(true);
          }}
        >
          Add
        </Button>
      </Box>
      <TableContainer component={Paper} sx={{ marginTop: 2, overflowX: 'auto' }}>
        <Table sx={{ tableLayout: 'fixed', width: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ p: 1, width: '25%' }}>Name</TableCell>
              <TableCell sx={{ p: 1, width: '25%' }}>phone</TableCell>
              <TableCell sx={{ p: 1, width: '25%' }}>email</TableCell>
              <TableCell sx={{ p: 1, width: '25%' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {coaches.map((coach) => (
              <TableRow key={coach?.id}>
                <TableCell
                  sx={{
                    p: 1,
                    width: '25%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {coach?.firstname} {coach?.lastname}
                </TableCell>
                <TableCell
                  sx={{
                    p: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '25%'
                  }}
                >
                  {coach?.phone}
                </TableCell>
                <TableCell
                  sx={{
                    p: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '25%'
                  }}
                >
                  {coach?.user?.email}
                </TableCell>
                <TableCell sx={{ p: 1, width: '10%' }}>
                  <IconButton onClick={() => handleEdit(coach)} color="primary">
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setCoachToDelete({
                        id: coach?.id,
                        id_user: coach?.user?.id
                      });
                      setDeleteModalOpen(true);
                    }}
                    color="secondary"
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        {pagination && <Pagination count={pagination.pageCount} page={activePage.page} onChange={handlePageChange} siblingCount={1} boundaryCount={1} shape="rounded" variant="outlined" />}
      </Box>
      <DeleteConfirmationModal
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        handleConfirm={() => handleDelete(coachToDelete)}
        title="Are you sure you want to delete this coach?"
      />
      <EditAddCoachModal open={editAddModalOpen} handleClose={() => setEditAddModalOpen(false)} handleSave={handleSave} initialData={currentCoach} />

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} variant="filled" sx={{ width: '100%' }}>
          {msg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Coaches;
