import React, { useState, useEffect } from 'react';
import { Container, Typography, FormControl, InputLabel, Select, MenuItem, Grid, Card, CardContent, Checkbox, TextField, IconButton, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const FrequencySelector = ({ onFrequencyChange, frequency, setFrequency }) => {
  const handleFrequencyChange = (event) => {
    const value = event.target.value;
    setFrequency(value);
    onFrequencyChange(value);
  };

  return (
    <FormControl fullWidth sx={{ my: 2 }}>
      <InputLabel id="frequency-select-label">Frequency</InputLabel>
      <Select labelId="frequency-select-label" value={frequency} onChange={handleFrequencyChange} label="Frequency">
        <MenuItem value="weekly">Weekly</MenuItem>
        <MenuItem value="monthly">Monthly</MenuItem>
        <MenuItem value="random">Random</MenuItem>
      </Select>
    </FormControl>
  );
};

const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const extractTime = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
};

const ScheduleSelector = ({ frequency, defaultDateTime, setDates, defaultDates }) => {
  const [selectedDays, setSelectedDays] = useState({});
  const [selectedTimes, setSelectedTimes] = useState({});
  const [randomDates, setRandomDates] = useState([{ date: '', time: extractTime(defaultDateTime) }]);
  const defaultTime = extractTime(defaultDateTime);

  const parseWeeklyCronString = (cronString) => {
    const parts = cronString.split(' ');
    const time = `${parts[2]}:${parts[1]}`;
    const days = parts[5].split(',').map((dayIndex) => weekDays[parseInt(dayIndex) - 1]);
    return { time, days };
  };
  const parseMonthlyCronString = (cronString) => {
    const parts = cronString.split(' ');
    const time = `${parts[2]}:${parts[1]}`;
    const days = parts[3].split(',').map((day) => parseInt(day));
    return { time, days };
  };

  useEffect(() => {
    if (!defaultDates?.length) {
      return;
    }
    if (frequency === 'weekly') {
      const initialSelectedDays = {};
      const selectedTimes = {};

      defaultDates.forEach((cronString) => {
        const { time, days } = parseWeeklyCronString(cronString);
        days.forEach((day) => {
          initialSelectedDays[day] = true;
          selectedTimes[day] = time;
        });
      });

      setSelectedDays(initialSelectedDays);
      setSelectedTimes(selectedTimes);
    } else if (frequency === 'monthly') {
      const initialSelectedDays = {};
      const selectedTimes = {};
      defaultDates.forEach((cronString) => {
        const { time, days } = parseMonthlyCronString(cronString);
        days.forEach((day) => {
          initialSelectedDays[day] = true;
          selectedTimes[day] = time;
        });
      });

      setSelectedDays(initialSelectedDays);
      setSelectedTimes(selectedTimes);
    } else if (frequency === 'random') {
      const parsedRandomDates = defaultDates.map((date) => {
        const [dateString, timeString] = date.split('T');
        return {
          date: dateString,
          time: `${timeString.split(':')[0]}:${timeString.split(':')[1]}`
        };
      });
      setRandomDates(parsedRandomDates);
    }
  }, [defaultDates, frequency]);

  useEffect(() => {
    if (frequency) {
      setDates([]);
    }
  }, [frequency]);

  useEffect(() => {
    if (frequency === 'random') {
      const filledRandomDates = randomDates.filter((dateObj) => dateObj.date !== '');
      setDates(filledRandomDates.map((dateObj) => `${dateObj.date}T${dateObj.time}:00`));
    } else if (frequency === 'weekly') {
      const cronStrings = [];
      const groupedDays = {};

      Object.keys(selectedDays).forEach((day) => {
        if (selectedDays[day]) {
          const time = selectedTimes[day] || defaultTime;
          const timeString = `${time.split(':')[1]} ${time.split(':')[0]}`;
          if (!groupedDays[timeString]) {
            groupedDays[timeString] = [];
          }
          groupedDays[timeString].push(weekDays.indexOf(day) + 1);
        }
      });

      Object.keys(groupedDays).forEach((timeString) => {
        const daysString = groupedDays[timeString].join(',');
        cronStrings.push(`0 ${timeString} * * ${daysString}`);
      });
      setDates(cronStrings);
    } else if (frequency === 'monthly') {
      const cronStrings = [];
      const groupedDays = {};

      Object.keys(selectedDays).forEach((day) => {
        if (selectedDays[day]) {
          const time = selectedTimes[day] || defaultTime;
          const timeString = `${time.split(':')[1]} ${time.split(':')[0]}`;
          if (!groupedDays[timeString]) {
            groupedDays[timeString] = [];
          }
          groupedDays[timeString].push(day);
        }
      });

      Object.keys(groupedDays).forEach((timeString) => {
        const daysString = groupedDays[timeString].join(',');
        cronStrings.push(`0 ${timeString} ${daysString} * *`);
      });

      setDates(cronStrings);
    }
  }, [randomDates, selectedDays, selectedTimes]);

  const handleDayChange = (day) => {
    setSelectedDays((prevSelectedDays) => ({
      ...prevSelectedDays,
      [day]: !prevSelectedDays[day]
    }));
  };

  const handleTimeChange = (day, event) => {
    const { value } = event.target;
    setSelectedTimes((prevSelectedTimes) => ({
      ...prevSelectedTimes,
      [day]: value
    }));
  };

  const handleRandomDateChange = (index, key, value) => {
    const updatedRandomDates = [...randomDates];
    updatedRandomDates[index][key] = value;
    setRandomDates(updatedRandomDates);
  };

  const addRandomDate = () => {
    setRandomDates([...randomDates, { date: '', time: defaultTime }]);
  };

  const removeRandomDate = (index) => {
    const updatedRandomDates = randomDates.filter((_, i) => i !== index);
    setRandomDates(updatedRandomDates);
  };

  const renderWeeklyOptions = () => (
    <Grid container>
      {weekDays.map((day) => (
        <Grid item xs={12} sm={6} md={4} key={day}>
          <Card>
            <CardContent>
              <Typography variant="body2">
                <Checkbox checked={!!selectedDays[day]} onChange={() => handleDayChange(day)} />
                {day}
              </Typography>
              {selectedDays[day] && (
                <TextField
                  type="time"
                  label="Time"
                  value={selectedTimes[day] || defaultTime}
                  onChange={(event) => handleTimeChange(day, event)}
                  InputLabelProps={{
                    shrink: true
                  }}
                  fullWidth
                />
              )}
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  const renderMonthlyOptions = () => {
    const daysInMonth = Array.from({ length: 31 }, (_, i) => i + 1);

    return (
      <Grid container>
        {daysInMonth.map((day) => (
          <Grid item xs={12} sm={6} md={4} key={day}>
            <Card>
              <CardContent>
                <Typography variant="body2">
                  <Checkbox checked={!!selectedDays[day]} onChange={() => handleDayChange(day)} />
                  Day {day}
                </Typography>
                {selectedDays[day] && (
                  <TextField
                    type="time"
                    label="Time"
                    value={selectedTimes[day] || defaultTime}
                    onChange={(event) => handleTimeChange(day, event)}
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

  const renderRandomOptions = () => (
    <>
      <Grid container spacing={2}>
        {randomDates.map((randomDate, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardContent>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={10}>
                    <TextField
                      type="date"
                      label="Date"
                      value={randomDate.date}
                      onChange={(event) => handleRandomDateChange(index, 'date', event.target.value)}
                      InputLabelProps={{
                        shrink: true
                      }}
                      fullWidth
                    />
                    <TextField
                      sx={{ mt: 2 }}
                      type="time"
                      label="Time"
                      value={randomDate.time}
                      onChange={(event) => handleRandomDateChange(index, 'time', event.target.value)}
                      InputLabelProps={{
                        shrink: true
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton onClick={() => removeRandomDate(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Button variant="contained" onClick={addRandomDate} sx={{ mt: 2 }}>
        Add Date
      </Button>
    </>
  );

  return (
    <div>
      {frequency === 'weekly' && renderWeeklyOptions()}
      {frequency === 'monthly' && renderMonthlyOptions()}
      {frequency === 'random' && renderRandomOptions()}
    </div>
  );
};

const ScheduleManager = ({ defaultDateTime, setDates, frequency, setFrequency, defaultDates }) => {
  const handleFrequencyChange = (value) => {
    setFrequency(value);
  };

  return (
    <>
      <FrequencySelector onFrequencyChange={handleFrequencyChange} frequency={frequency} setFrequency={setFrequency} />
      {frequency && <ScheduleSelector frequency={frequency} defaultDateTime={defaultDateTime} setDates={setDates} defaultDates={defaultDates} />}
    </>
  );
};

export default ScheduleManager;
