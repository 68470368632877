import DeleteConfirmationModal from '../components/common/DeleteConfirmationModal';
import EditAddRoomModal from '../components/Rooms/EditAddRoomModal';
import EditAddRoomLayoutModal from '../components/Rooms/EditAddRoomLayoutModal';
import React, { useEffect, useState } from 'react';
import { Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Paper, Box, Pagination, Alert, Snackbar } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { fetchAllRooms, useRoomsContext, deleteRoom } from '../store/rooms';

const Rooms = () => {
  const { state, dispatch } = useRoomsContext();
  const rooms = state.all || [];
  const pagination = state.pagination;
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editAddModalOpen, setEditAddModalOpen] = useState(false);
  const [editAddModalOpenLayout, setEditAddModalOpenLayout] = useState(false);
  const [currentRoom, setCurrentRoom] = useState(null);
  const [roomToDelete, setRoomToDelete] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState(false);
  const [severity, setSeverity] = useState(false);
  const [lines, setLines] = useState([]);

  const pageSize = 10;

  useEffect(() => {
    refetchData();
  }, [activePage]);

  const handleEdit = (room) => {
    setCurrentRoom(room);
    setEditAddModalOpen(true);
  };
  const handleEditLayout = (room) => {
    setCurrentRoom(room);
    const initialData = room.lines.map((l) => ({
      length: l.length,
      points: [l.start_x, l.start_y, l.end_x, l.end_y]
    }));
    setLines(JSON.parse(JSON.stringify(initialData)));
    setEditAddModalOpenLayout(true);
  };

  const handleDelete = (id) => {
    let jwt = localStorage.getItem('jwt');

    deleteRoom(dispatch, { jwt, id }).then((res) => {
      setOpen(true);
      if (res === 'OK') {
        refetchData();
        setMsg('Room deleted Successfully');
        setSeverity('success');
      } else {
        setMsg('Problem while deleting Room');
        setSeverity('error');
      }
    });

    setDeleteModalOpen(false);
  };

  const handleSave = ({ message, variant }) => {
    setOpen(true);
    setMsg(message);
    setSeverity(variant);
    setEditAddModalOpen(false);
    setEditAddModalOpenLayout(false);
    refetchData();
  };

  const handlePageChange = (event, page) => {
    setActivePage(page);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const refetchData = () => {
    let jwt = localStorage.getItem('jwt');
    fetchAllRooms(dispatch, { jwt, pageSize, page: activePage });
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3
        }}
      >
        <Typography variant="h4">Rooms</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setCurrentRoom(null);
            setEditAddModalOpen(true);
          }}
        >
          Add
        </Button>
      </Box>
      <TableContainer component={Paper} sx={{ marginTop: 2, overflowX: 'auto' }}>
        <Table sx={{ tableLayout: 'fixed', width: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ p: 1, width: '20%' }}>Name</TableCell>
              <TableCell sx={{ p: 1, width: '60%' }}>Description</TableCell>
              <TableCell sx={{ p: 1, width: '10%' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rooms.map((room) => (
              <TableRow key={room.id}>
                <TableCell
                  sx={{
                    p: 1,
                    width: '20%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {room.name}
                </TableCell>
                <TableCell
                  sx={{
                    p: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '60%'
                  }}
                >
                  {room.description}
                </TableCell>
                <TableCell sx={{ p: 1, width: '10%' }}>
                  <IconButton onClick={() => handleEdit(room)} color="primary">
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setRoomToDelete(room.id);
                      setDeleteModalOpen(true);
                    }}
                    color="secondary"
                  >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton onClick={() => handleEditLayout(room)} color="primary">
                    <AddIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        {pagination && <Pagination count={pagination.pageCount} page={activePage.page} onChange={handlePageChange} siblingCount={1} boundaryCount={1} shape="rounded" variant="outlined" />}
      </Box>
      <DeleteConfirmationModal
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        handleConfirm={() => handleDelete(roomToDelete)}
        title="Are you sure you want to delete this room?"
      />
      <EditAddRoomModal open={editAddModalOpen} handleClose={() => setEditAddModalOpen(false)} handleSave={handleSave} initialData={currentRoom} />
      <EditAddRoomLayoutModal
        open={editAddModalOpenLayout}
        handleClose={() => setEditAddModalOpenLayout(false)}
        handleSave={handleSave}
        initialData={currentRoom}
        lines={lines}
        refetchData={refetchData}
      />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} variant="filled" sx={{ width: '100%' }}>
          {msg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Rooms;
