import CancelConfirmationModal from '../components/common/CancelConfirmationModal';
import DeleteConfirmationModal from '../components/common/DeleteConfirmationModal';
import EditAddSessionModal from '../components/Sessions/EditAddSessionModal';
import React, { useEffect, useState } from 'react';
import { Switch, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Paper, Box, Pagination, Alert, Snackbar } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CopyIcon from '@mui/icons-material/ContentCopy';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { fetchAllSessions, useSessionsContext, deleteSession, createSession, toggleActiveSession, cancelSession } from '../store/sessions';

const Sessions = () => {
  const { state, dispatch } = useSessionsContext();
  const sessions = state.all || [];
  const pagination = state.pagination;
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [copyModalOpen, setCopyModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [editAddModalOpen, setEditAddModalOpen] = useState(false);
  const [currentSession, setCurrentSession] = useState(null);
  const [sessionToDelete, setSessionToDelete] = useState(null);
  const [sessionToCopy, setSessionToCopy] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState(false);
  const [severity, setSeverity] = useState(false);
  const [lines, setLines] = useState([]);
  const [canceledDate, setCanceledDate] = useState(null);
  const [sessionToCancel, setSessionToCancel] = useState(null);

  const pageSize = 10;

  useEffect(() => {
    refetchData();
  }, [activePage]);

  const handleEdit = (session) => {
    setCurrentSession(session);
    setEditAddModalOpen(true);
  };
  const handleDelete = (id) => {
    let jwt = localStorage.getItem('jwt');

    deleteSession(dispatch, { jwt, id }).then((res) => {
      setOpen(true);
      if (res === 'OK') {
        refetchData();
        setMsg('Session deleted Successfully');
        setSeverity('success');
      } else {
        setMsg('Problem while deleting Session');
        setSeverity('error');
      }
    });

    setDeleteModalOpen(false);
  };

  const handleSave = ({ message, variant }) => {
    setOpen(true);
    setMsg(message);
    setSeverity(variant);
    setEditAddModalOpen(false);
    refetchData();
  };

  const handlePageChange = (event, page) => {
    setActivePage(page);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const refetchData = () => {
    let jwt = localStorage.getItem('jwt');
    fetchAllSessions(dispatch, { jwt, pageSize, page: activePage });
  };

  const cancelSessionRequest = () => {
    let jwt = localStorage.getItem('jwt');
    cancelSession(dispatch, {
      jwt,
      datetime: canceledDate,
      session: sessionToCancel.id,
    }).then((res) => {
      if (res.status === 'OK') {
        handleSave({
          message: 'Session canceled Successfully',
          variant: 'success'
        });
      } else {
        handleSave({ message: res.errorMsg, variant: 'error' });
      }
      setCancelModalOpen(false);
    });
  };
  const duplicateSession = () => {
    let jwt = localStorage.getItem('jwt');
    createSession(dispatch, {
      jwt,
      name: sessionToCopy.name,
      description: sessionToCopy.description,
      start_date: sessionToCopy.start_date,
      end_date: sessionToCopy.end_date,
      activity: sessionToCopy.activity.id,
      room_layout: sessionToCopy.room_layout.id,
      coach: sessionToCopy.coach.id,
      price: sessionToCopy.price,
      defaultPhotos: sessionToCopy?.photos?.length ? sessionToCopy?.photos.map((el) => el.id) : []
    }).then((res) => {
      if (res === 'OK') {
        handleSave({
          message: 'Session copied Successfully',
          variant: 'success'
        });
      } else {
        handleSave({ message: 'Problem while copy Session', variant: 'error' });
      }
      setCopyModalOpen(false);
    });
  };

  const handleChange = (active, id) => {
    let jwt = localStorage.getItem('jwt');

    toggleActiveSession(dispatch, {
      jwt,
      active,
      id
    }).then((res) => {
      if (res === 'OK') {
        handleSave({
          message: `Session ${active ? 'activated' : 'desactivated'} Successfully`,
          variant: 'success'
        });
      } else {
        handleSave({
          message: `Problem while ${active ? 'activate' : 'desactivate'} Session`,
          variant: 'error'
        });
      }
    });
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3
        }}
      >
        <Typography variant="h4">Sessions</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setCurrentSession(null);
            setEditAddModalOpen(true);
          }}
        >
          Add
        </Button>
      </Box>
      <TableContainer component={Paper} sx={{ marginTop: 2, overflowX: 'auto' }}>
        <Table sx={{ tableLayout: 'fixed', width: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ p: 1, width: '5%' }}>id</TableCell>
              <TableCell sx={{ p: 1, width: '16%' }}>Name</TableCell>
              <TableCell sx={{ p: 1, width: '16%' }}>Coach</TableCell>
              <TableCell sx={{ p: 1, width: '16%' }}>Room</TableCell>
              <TableCell sx={{ p: 1, width: '16%' }}>Price</TableCell>
              <TableCell sx={{ p: 1, width: '16%' }}>status</TableCell>
              <TableCell sx={{ p: 1, width: '15%' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sessions.map((session) => (
              <TableRow key={session.id}>
                <TableCell
                  sx={{
                    p: 1,
                    width: '20%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {session.id}
                </TableCell>
                <TableCell
                  sx={{
                    p: 1,
                    width: '20%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {session.name}
                </TableCell>
                <TableCell
                  sx={{
                    p: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '20%'
                  }}
                >
                  {session.coach.firstname} {session.coach.lastname}
                </TableCell>
                <TableCell
                  sx={{
                    p: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '20%'
                  }}
                >
                  {session.room_layout.room.name}: {session.room_layout.name}
                </TableCell>
                <TableCell
                  sx={{
                    p: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '20%'
                  }}
                >
                  {session.price}
                </TableCell>
                <TableCell
                  sx={{
                    p: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '20%'
                  }}
                >
                  <Switch checked={session.active} onChange={(e) => handleChange(e.target.checked, session.id)} inputProps={{ 'aria-label': 'controlled' }} />
                </TableCell>
                <TableCell sx={{ p: 1, width: '20%' }}>
                  <IconButton onClick={() => handleEdit(session)} color="primary">
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setSessionToDelete(session.id);
                      setDeleteModalOpen(true);
                    }}
                    color="secondary"
                  >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setSessionToCopy(session);
                      setCopyModalOpen(true);
                    }}
                    color="secondary"
                  >
                    <CopyIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setSessionToCancel(session);
                      setCancelModalOpen(true);
                    }}
                    color="error"
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        {pagination && <Pagination count={pagination.pageCount} page={activePage.page} onChange={handlePageChange} siblingCount={1} boundaryCount={1} shape="rounded" variant="outlined" />}
      </Box>
      <DeleteConfirmationModal
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        handleConfirm={() => handleDelete(sessionToDelete)}
        title="Are you sure you want to delete this session?"
      />
      <DeleteConfirmationModal open={copyModalOpen} handleClose={() => setCopyModalOpen(false)} handleConfirm={() => duplicateSession()} title="Are you sure you want to Copy this session?" />
      <CancelConfirmationModal 
        open={cancelModalOpen} 
        handleClose={() => setCancelModalOpen(false)} 
        canceledDate={canceledDate}
        setCanceledDate={setCanceledDate}
        handleConfirm={() => cancelSessionRequest()} 
      />
      <EditAddSessionModal open={editAddModalOpen} handleClose={() => setEditAddModalOpen(false)} handleSave={handleSave} initialData={currentSession} />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} variant="filled" sx={{ width: '100%' }}>
          {msg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Sessions;
