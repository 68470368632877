import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import DefaultLayout from './layouts/DefaultLayout';
import AuthLayout from './layouts/AuthLayout';
import Home from './pages/Home';
import Activities from './pages/Activities';
import Coaches from './pages/Coaches';
import Reservations from './pages/Reservations';
import Rooms from './pages/Rooms';
import Sessions from './pages/Sessions';
import Login from './pages/Login';
import AddRoom from './pages/AddRoom';
import SpecialOffer from './pages/SpecialOffer';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <DefaultLayout>
              <Home />
            </DefaultLayout>
          }
        />
        <Route
          path="/activities"
          element={
            <DefaultLayout>
              <Activities />
            </DefaultLayout>
          }
        />
        <Route
          path="/coaches"
          element={
            <DefaultLayout>
              <Coaches />
            </DefaultLayout>
          }
        />
        <Route
          path="/reservations"
          element={
            <DefaultLayout>
              <Reservations />
            </DefaultLayout>
          }
        />
        <Route
          path="/rooms"
          element={
            <DefaultLayout>
              <Rooms />
            </DefaultLayout>
          }
        />
        <Route
          path="/sessions"
          element={
            <DefaultLayout>
              <Sessions />
            </DefaultLayout>
          }
        />
        <Route
          path="/add-room"
          element={
            <DefaultLayout>
              <AddRoom />
            </DefaultLayout>
          }
        />
        <Route
          path="/add-room/:id"
          element={
            <DefaultLayout>
              <AddRoom />
            </DefaultLayout>
          }
        />
        <Route
          path="/login"
          element={
            <AuthLayout>
              <Login />
            </AuthLayout>
          }
        />
        <Route
          path="/special-offer"
          element={
            <DefaultLayout>
              <SpecialOffer />
            </DefaultLayout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
