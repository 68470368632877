import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Typography, Paper, Box, IconButton, Grid, Alert, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useUserContext } from '../../store/user';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { createSpecialOffer, updateSpecialOffer } from '../../store/specialOffer';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});


function SpecialOfferModal({ open, handleClose, handleSave, initialData }) {
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [points, setPoints] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [description, setDescription] = useState('');
  const [features, setFeatures] = useState([{ feature: '' }]);
  const [image, setImage] = useState([]);
  const [files, setFiles] = useState([]);
  const { dispatch } = useUserContext();
  const [errorMsg, setErrorMsg] = useState('');
  const [popular, setPopular] = useState(false);

  useEffect(() => {
    if (initialData) {
      setTitle(initialData.title);
      setPrice(initialData.price);
      setPoints(initialData.points)
      setSubtitle(initialData.subtitle);
      setDescription(initialData.description);
      setFeatures(initialData.features || [{ feature: '' }]);
      setImage([initialData.hero_section_image] || []);
      setPopular(initialData.popular)

    } else {
      setTitle('');
      setPrice('');
      setPoints('');
      setSubtitle('');
      setDescription('');
      setFeatures([{ feature: '' }]);
      setFiles([]);
      setImage([]);
      setPopular(false)

    }
  }, [initialData]);

  const handleDeletePhoto = (index) => {
    const updatedPhotos = [...image];
    updatedPhotos.splice(index, 1);
    setImage(updatedPhotos);
  };
  const handleDeleteFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const handleFormSubmit = () => {
    if (!title) {
      setErrorMsg('Add title');
      return;
    }
    if (!price) {
      setErrorMsg('Add Price');
      return;
    }
    if (!subtitle) {
      setErrorMsg('Add subtitle');
      return;
    }
    const formData = new FormData();
    let uploadFiles = false;
    for (let index = 0; index < files.length; index++) {
      uploadFiles = true;
      const file = files[index];
      formData.append('files', file);
    }
    let jwt = localStorage.getItem('jwt');
    if (initialData) {
      updateSpecialOffer(dispatch, {
        jwt,
        files: formData,
        initialPhotos: image,
        title,
        price,
        points,
        subtitle,
        description,
        features,
        popular,
        id: initialData.id,
        uploadFiles
      }).then((res) => {
        if (res === 'OK') {
          handleSave({
            message: 'Special Offer updated Successfully',
            variant: 'success'
          });
        } else {
          handleSave({
            message: 'Problem while updating Special Offer',
            variant: 'error'
          });
        }
      });
    } else {
      createSpecialOffer(dispatch, {
        jwt,
        files: formData,
        title,
        price,
        points,
        subtitle,
        description,
        features,
        popular,
        uploadFiles
      }).then((res) => {
        if (res === 'OK') {
          handleSave({
            message: 'Special Offer created Successfully',
            variant: 'success'
          });
        } else {
          handleSave({
            message: 'Problem while creating Special Offer',
            variant: 'error'
          });
        }
      });
    }
  };

  const addPhotos = (photos) => {
    setFiles(photos);
  };

  const handleFeatureyChange = (index, field, value) => {
    const newGallery = [...features];
    newGallery[index][field] = value;
    setFeatures(newGallery);
  };

  const addFeatureItem = () => {
    setFeatures([...features, { feature: '' }]);
  };
  const deleteFeatureItem = (index) => {
    const newFeatures = features.filter((_, i) => i !== index);
    setFeatures(newFeatures);
  };
  const handleSwitchChange = (event) => {
    setPopular(event.target.checked);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid #ccc' }}>
        <DialogTitle>
          {initialData ? 'Edit Special Offers' : 'Add Special Offers'}
        </DialogTitle>
        <Switch checked={popular} onChange={handleSwitchChange} />
      </div>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Title"
          type="text"
          fullWidth
          value={title}
          onChange={(e) => {
            setErrorMsg('');
            setTitle(e.target.value);
          }}
        />
        <TextField
          autoFocus
          margin="dense"
          label="Price"
          type="text"
          fullWidth
          value={price}
          onChange={(e) => {
            setErrorMsg('');
            setPrice(e.target.value);
          }}
        />
           <TextField
          autoFocus
          margin="dense"
          label="Points"
          type="text"
          fullWidth
          value={points}
          onChange={(e) => {
            setErrorMsg('');
            setPoints(e.target.value);
          }}
        />
        <TextField autoFocus margin="dense" label="Subtitle" type="text" fullWidth value={subtitle} onChange={(e) => setSubtitle(e.target.value)} />
        <TextField margin="dense" label="Description" type="text" fullWidth value={description} multiline rows={3} onChange={(e) => setDescription(e.target.value)} />
        {features.map((item, index) => (
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <TextField
              label="feature"
              value={item.feature}
              onChange={(e) => handleFeatureyChange(index, 'feature', e.target.value)}
              margin="normal"
              fullWidth
            />
            <IconButton onClick={() => deleteFeatureItem(index)} aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
        <Button variant="outlined" onClick={addFeatureItem} sx={{ mt: 2 }}>
          Add feature
        </Button>
        <Box sx={{ mt: 2 }}>
          <p sx={{ my: 4 }}>Photos</p>
          {image.length || files.length ? (
            <Grid container spacing={2}>
              {image.map((photo, index) => (
                <Grid item xs={4} key={index}>
                  <Paper
                    sx={{
                      position: 'relative',
                      paddingTop: '100%',
                      overflow: 'hidden'
                    }}
                  >
                    <img
                      src={photo?.formats?.thumbnail?.url ? `${process.env.REACT_APP_SERVER_URL}${photo?.formats?.thumbnail?.url}` : ''}
                      alt={`Activity Photo ${index}`}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                      }}
                    />
                    <IconButton
                      onClick={() => handleDeletePhoto(index)}
                      sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        color: 'red'
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Paper>
                </Grid>
              ))}
              {Array.from(files).map((photo, index) => (
                <Grid item xs={4} key={index}>
                  <Paper
                    sx={{
                      position: 'relative',
                      paddingTop: '100%',
                      overflow: 'hidden'
                    }}
                  >
                    <img
                      src={URL.createObjectURL(photo)}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                      }}
                    />
                    <IconButton
                      onClick={() => handleDeleteFile(index)}
                      sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        color: 'red'
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          ) : (
            <p sx={{ my: 4 }}>No Photos</p>
          )}
        </Box>
        {errorMsg && (
          <Alert severity="error" sx={{ my: 2 }}>
            {errorMsg}
          </Alert>
        )}
        <Button component="label" role={undefined} variant="contained" tabIndex={-1} startIcon={<CloudUploadIcon />}>
          Upload file
          <VisuallyHiddenInput type="file" multiple onChange={(e) => addPhotos(e.target.files)} />
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={() => handleFormSubmit()} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>)
}

export default SpecialOfferModal