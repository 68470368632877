import React, { createContext, useReducer, useContext } from 'react';
import axios from 'axios';

const SessionsContext = createContext();

const userReducer = (state, action) => {
  switch (action.type) {
    case 'SET_ALL':
      return { ...state, all: action.payload };
    case 'SET_PAGINATION':
      return { ...state, pagination: action.payload };
    default:
      return state;
  }
};
const initialState = {
  all: [],
  pagination: null
};

export const SessionsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, { ...initialState });

  return <SessionsContext.Provider value={{ state, dispatch }}>{children}</SessionsContext.Provider>;
};

export const useSessionsContext = () => useContext(SessionsContext);

export const fetchAllSessions = async (dispatch, { jwt, page, pageSize }) => {
  try {
    let request = `${process.env.REACT_APP_SERVER_URL}/api/sessions?populate[coach][populate]=*&populate[activity][populate]=*&populate[photos][populate]=*&populate[dates][populate]=*&populate[room_layout][populate][room][populate]=*&sort[1]=id:desc`;

    if (page) {
      request += `&pagination[page]=${page}`;
    }
    if (pageSize) {
      request += `&pagination[pageSize]=${pageSize}`;
    }

    let result = await axios.get(request, {
      headers: {
        Authorization: 'Bearer ' + jwt
      }
    });
    if (result?.data?.data?.length) {
      dispatch({ type: 'SET_ALL', payload: result?.data?.data });
      dispatch({
        type: 'SET_PAGINATION',
        payload: result?.data?.meta.pagination
      });
    }
  } catch (error) {
    console.log({ error });
    return false;
  }
};

export const createSession = async (dispatch, { jwt, files, uploadFiles, name, start_date, end_date, description, activity, room_layout, price, duration, coach, dates, frequency, defaultPhotos, active }) => {
  try {
    let photos = [];
    if (uploadFiles) {
      let uploadFiles = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/upload/`, files, { headers: { Authorization: 'Bearer ' + jwt } });
      if (uploadFiles?.data?.length) photos = uploadFiles?.data?.map((f) => f.id);
    }
    if (defaultPhotos?.length) {
      photos = defaultPhotos;
    }
    let result = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/sessions`,
      {
        data: {
          name,
          description,
          activity,
          room_layout,
          price,
          duration,
          coach,
          dates,
          start_date,
          end_date,
          photos,
          frequency,
          active
        }
      },
      {
        headers: { Authorization: 'Bearer ' + jwt }
      }
    );

    if (result?.data) {
      return 'OK';
    } else {
      return 'KO';
    }
  } catch (error) {
    console.log({ error });
    return 'KO';
  }
};

export const updateSession = async (
  dispatch,
  { jwt, files, uploadFiles, name, description, activity, room_layout, price, duration, coach, dates, initialPhotos, start_date, end_date, id, frequency, active }
) => {
  try {
    let photos = [];
    if (uploadFiles) {
      let uploadFiles = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/upload/`, files, { headers: { Authorization: 'Bearer ' + jwt } });
      if (uploadFiles?.data?.length) photos = uploadFiles?.data?.map((f) => f.id);
    }
    if (initialPhotos) {
      photos = [...photos, ...initialPhotos.map((el) => el.id)];
    }
    let result = await axios.put(
      `${process.env.REACT_APP_SERVER_URL}/api/sessions/${id}`,
      {
        data: {
          name,
          description,
          activity,
          room_layout,
          price,
          duration,
          coach,
          dates,
          start_date,
          end_date,
          frequency,
          active
        }
      },
      {
        headers: { Authorization: 'Bearer ' + jwt }
      }
    );

    if (result?.data) {
      return 'OK';
    } else {
      return 'KO';
    }
  } catch (error) {
    console.log({ error });
    return 'KO';
  }
};
export const toggleActiveSession = async (dispatch, { jwt, id, active }) => {
  try {
    let result = await axios.put(
      `${process.env.REACT_APP_SERVER_URL}/api/sessions/${id}`,
      {
        data: { active }
      },
      {
        headers: { Authorization: 'Bearer ' + jwt }
      }
    );

    if (result?.data) {
      return 'OK';
    } else {
      return 'KO';
    }
  } catch (error) {
    console.log({ error });
    return 'KO';
  }
};

export const deleteSession = async (dispatch, { id, jwt }) => {
  try {
    let result = await axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/sessions/${id}`, {
      headers: { Authorization: 'Bearer ' + jwt }
    });

    if (result?.data) {
      return 'OK';
    } else {
      return 'KO';
    }
  } catch (error) {
    console.log({ error });
    return 'KO';
  }
};

export const cancelSession = async (dispatch, { datetime, session, jwt }) => {
  try {
    let result = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/user-account/cancel-session`, {
      data: { datetime, session }
    }, {
      headers: { Authorization: 'Bearer ' + jwt }
    });
    return result?.data;
  } catch (error) {
    console.log({ error });
    return 'KO';
  }
};
