import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Button } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';
import { requestLogout, useUserContext } from '../../store/user';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const { state, dispatch } = useUserContext();
  const navigate = useNavigate();

  const handleLogout = () => {
    requestLogout(dispatch).then(() => {
      navigate('/login');
    });
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: 'white',
        color: 'black',
        boxShadow: 'none',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        left: 240,
        width: `calc(100% - 240px)`,
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
      }}
    >
      <Toolbar>
        <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}></Typography>
        <div>
          {state.isAuth ? (
            <IconButton color="inherit" onClick={() => handleLogout()}>
              <LogoutIcon />
            </IconButton>
          ) : (
            <Button color="inherit" component={Link} to="/login">
              Login
            </Button>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
