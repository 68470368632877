import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Typography, Paper, Box, IconButton, Grid, Alert } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { updateActivity, createActivity, useActivitiesContext } from '../../store/activities';
import CloseIcon from '@mui/icons-material/Close';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const EditAddActivityModal = ({ open, handleClose, handleSave, initialData }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [photos, setPhotos] = useState([]);
  const [files, setFiles] = useState([]);
  const { dispatch } = useActivitiesContext();
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    if (initialData) {
      setName(initialData.name);
      setDescription(initialData.description);
      setPhotos(initialData.photos || []);
    } else {
      setName('');
      setDescription('');
      setPhotos([]);
      setFiles([]);
    }
  }, [initialData]);

  const handleDeletePhoto = (index) => {
    const updatedPhotos = [...photos];
    updatedPhotos.splice(index, 1);
    setPhotos(updatedPhotos);
  };
  const handleDeleteFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const handleFormSubmit = () => {
    if (!name) {
      setErrorMsg('Add a Name');
      return;
    }
    const formData = new FormData();
    let uploadFiles = false;
    for (let index = 0; index < files.length; index++) {
      uploadFiles = true;
      const file = files[index];
      formData.append('files', file);
    }
    let jwt = localStorage.getItem('jwt');

    if (initialData) {
      updateActivity(dispatch, {
        jwt,
        files: formData,
        initialPhotos: photos,
        name,
        description,
        id: initialData.id,
        uploadFiles
      }).then((res) => {
        if (res === 'OK') {
          handleSave({
            message: 'Activity updated Successfully',
            variant: 'success'
          });
        } else {
          handleSave({
            message: 'Problem while updating Activity',
            variant: 'error'
          });
        }
      });
    } else {
      createActivity(dispatch, {
        jwt,
        files: formData,
        name,
        description,
        uploadFiles
      }).then((res) => {
        if (res === 'OK') {
          handleSave({
            message: 'Activity created Successfully',
            variant: 'success'
          });
        } else {
          handleSave({
            message: 'Problem while creating Activity',
            variant: 'error'
          });
        }
      });
    }
  };

  const addPhotos = (photos) => {
    setFiles(photos);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{initialData ? 'Edit Activity' : 'Add Activity'}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Name"
          type="text"
          fullWidth
          value={name}
          onChange={(e) => {
            setErrorMsg('');
            setName(e.target.value);
          }}
        />
        <TextField margin="dense" label="Description" type="text" fullWidth value={description} multiline rows={4} onChange={(e) => setDescription(e.target.value)} />
        <Box sx={{ mt: 2 }}>
          <p sx={{ my: 4 }}>Photos</p>
          {photos.length || files.length ? (
            <Grid container spacing={2}>
              {photos.map((photo, index) => (
                <Grid item xs={4} key={index}>
                  <Paper
                    sx={{
                      position: 'relative',
                      paddingTop: '100%',
                      overflow: 'hidden'
                    }}
                  >
                    <img
                      src={photo?.formats?.thumbnail?.url ? `${process.env.REACT_APP_SERVER_URL}${photo?.formats?.thumbnail?.url}` : ''}
                      alt={`Activity Photo ${index}`}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                      }}
                    />
                    <IconButton
                      onClick={() => handleDeletePhoto(index)}
                      sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        color: 'red'
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Paper>
                </Grid>
              ))}
              {Array.from(files).map((photo, index) => (
                <Grid item xs={4} key={index}>
                  <Paper
                    sx={{
                      position: 'relative',
                      paddingTop: '100%',
                      overflow: 'hidden'
                    }}
                  >
                    <img
                      src={URL.createObjectURL(photo)}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                      }}
                    />
                    <IconButton
                      onClick={() => handleDeleteFile(index)}
                      sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        color: 'red'
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          ) : (
            <p sx={{ my: 4 }}>No Photos</p>
          )}
        </Box>
        {errorMsg && (
          <Alert severity="error" sx={{ my: 2 }}>
            {errorMsg}
          </Alert>
        )}
        <Button component="label" role={undefined} variant="contained" tabIndex={-1} startIcon={<CloudUploadIcon />}>
          Upload file
          <VisuallyHiddenInput type="file" multiple onChange={(e) => addPhotos(e.target.files)} />
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={() => handleFormSubmit()} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditAddActivityModal;
