import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, Button, TextField, Typography, Paper, Box, IconButton, Grid, Alert, AppBar, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { updateRoom, createRoom, useRoomsContext } from '../../store/rooms';
import CloseIcon from '@mui/icons-material/Close';
import DrawingBoard from './DrawingBoard';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const EditAddRoomModal = ({ open, handleClose, handleSave, initialData }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [photos, setPhotos] = useState([]);
  const [files, setFiles] = useState([]);
  const { dispatch } = useRoomsContext();
  const [errorMsg, setErrorMsg] = useState('');
  const [lines, setLines] = useState([]);

  useEffect(() => {
    if (initialData) {
      setName(initialData.name);
      setDescription(initialData.description);
      setPhotos(initialData.photos || []);
      setLines(
        initialData.lines.map((l) => ({
          length: l.length,
          points: [l.start_x, l.start_y, l.end_x, l.end_y]
        }))
      );
    } else {
      initData();
    }
  }, [initialData]);

  const initData = () => {
    setName('');
    setDescription('');
    setPhotos([]);
    setFiles([]);
    setLines([]);
  };
  const handleDeletePhoto = (index) => {
    const updatedPhotos = [...photos];
    updatedPhotos.splice(index, 1);
    setPhotos(updatedPhotos);
  };
  const handleDeleteFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  function normalizeCoordinates(lines) {
    let minX = Math.min(...lines.flatMap((line) => [line.points[0], line.points[2]]));
    let minY = Math.min(...lines.flatMap((line) => [line.points[1], line.points[3]]));

    return lines.map((line) => ({
      length: line.length,
      start_x: line.points[0] - minX,
      start_y: line.points[1] - minY,
      end_x: line.points[2] - minX,
      end_y: line.points[3] - minY
    }));
  }

  const handleFormSubmit = () => {
    if (!name) {
      setErrorMsg('Add a Name');
      return;
    }
    const linesToSend = normalizeCoordinates(lines);
    const formData = new FormData();
    let uploadFiles = false;
    for (let index = 0; index < files.length; index++) {
      uploadFiles = true;
      const file = files[index];
      formData.append('files', file);
    }
    let jwt = localStorage.getItem('jwt');
    if (initialData) {
      updateRoom(dispatch, {
        jwt,
        files: formData,
        initialPhotos: photos,
        name,
        description,
        id: initialData.id,
        uploadFiles
      }).then((res) => {
        if (res === 'OK') {
          handleSave({
            message: 'Room updated Successfully',
            variant: 'success'
          });
        } else {
          handleSave({
            message: 'Problem while updating Room',
            variant: 'error'
          });
        }
        initData();
      });
    } else {
      createRoom(dispatch, {
        jwt,
        files: formData,
        name,
        description,
        uploadFiles,
        lines: linesToSend
      }).then((res) => {
        if (res === 'OK') {
          handleSave({
            message: 'Room created Successfully',
            variant: 'success'
          });
        } else {
          handleSave({
            message: 'Problem while creating Room',
            variant: 'error'
          });
        }
        initData();
      });
    }
  };

  const addPhotos = (photos) => {
    setFiles(photos);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullScreen>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {initialData ? 'Edit Room' : 'Add Room'}
          </Typography>
          <Button autoFocus color="inherit" onClick={handleFormSubmit}>
            save
          </Button>
        </Toolbar>
      </AppBar>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <DrawingBoard lines={lines} setLines={setLines}></DrawingBoard>
          </Grid>
          <Grid item xs={3}>
            <TextField
              autoFocus
              margin="dense"
              label="Name"
              type="text"
              fullWidth
              value={name}
              onChange={(e) => {
                setErrorMsg('');
                setName(e.target.value);
              }}
            />
            <TextField margin="dense" label="Description" type="text" fullWidth value={description} multiline rows={4} onChange={(e) => setDescription(e.target.value)} />
            <Box sx={{ mt: 2 }}>
              <p sx={{ my: 4 }}>Photos</p>
              {photos.length || files.length ? (
                <Grid container spacing={2}>
                  {photos.map((photo, index) => (
                    <Grid item xs={4} key={index}>
                      <Paper
                        sx={{
                          position: 'relative',
                          paddingTop: '100%',
                          overflow: 'hidden'
                        }}
                      >
                        <img
                          src={photo?.formats?.thumbnail?.url ? `${process.env.REACT_APP_SERVER_URL}${photo?.formats?.thumbnail?.url}` : ''}
                          alt={`Room Photo ${index}`}
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover'
                          }}
                        />
                        <IconButton
                          onClick={() => handleDeletePhoto(index)}
                          sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            color: 'red'
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Paper>
                    </Grid>
                  ))}
                  {Array.from(files).map((photo, index) => (
                    <Grid item xs={4} key={index}>
                      <Paper
                        sx={{
                          position: 'relative',
                          paddingTop: '100%',
                          overflow: 'hidden'
                        }}
                      >
                        <img
                          src={URL.createObjectURL(photo)}
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover'
                          }}
                        />
                        <IconButton
                          onClick={() => handleDeleteFile(index)}
                          sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            color: 'red'
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <p sx={{ my: 4 }}>No Photos</p>
              )}
            </Box>
            {errorMsg && (
              <Alert severity="error" sx={{ my: 2 }}>
                {errorMsg}
              </Alert>
            )}
            <Button component="label" role={undefined} variant="contained" tabIndex={-1} startIcon={<CloudUploadIcon />}>
              Upload file
              <VisuallyHiddenInput type="file" multiple onChange={(e) => addPhotos(e.target.files)} />
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default EditAddRoomModal;
