import React from 'react';
import { UserProvider } from './user';
import { ActivitiesProvider } from './activities';
import { RoomsProvider } from './rooms';
import { SessionsProvider } from './sessions';
import { ReservationsProvider } from './reservations';
import { SpecialOffersProvider } from './specialOffer';

const StoreProvider = ({ children }) => (
  <UserProvider>
    <SpecialOffersProvider>
      <ActivitiesProvider>
        <RoomsProvider>
          <SessionsProvider>
            <ReservationsProvider>{children}</ReservationsProvider>
          </SessionsProvider>
        </RoomsProvider>
      </ActivitiesProvider>
    </SpecialOffersProvider>
  </UserProvider>
);

export default StoreProvider;
