import React, { createContext, useReducer, useContext } from 'react';
import axios from 'axios';

const SpecialOffersContext = createContext();

const userReducer = (state, action) => {
  switch (action.type) {
    case 'SET_ALL':
      return { ...state, all: action.payload };
    case 'SET_PAGINATION':
      return { ...state, pagination: action.payload };
    default:
      return state;
  }
};
const initialState = {
  all: [],
  pagination: null
};

export const SpecialOffersProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, { ...initialState });

  return <SpecialOffersContext.Provider value={{ state, dispatch }}>{children}</SpecialOffersContext.Provider>;
};

export const useSpecialOffersContext = () => useContext(SpecialOffersContext);

export const fetchAllSpecialOffers = async (dispatch, { jwt, page, pageSize }) => {
  try {
    let request = `${process.env.REACT_APP_SERVER_URL}/api/special-offers?populate=*&sort[1]=id:desc`;

    if (page) {
      request += `&pagination[page]=${page}`;
    }
    if (pageSize) {
      request += `&pagination[pageSize]=${pageSize}`;
    }

    let result = await axios.get(request, {
      headers: {
        Authorization: 'Bearer ' + jwt
      }
    });
    if (result?.data?.data?.length) {
      dispatch({ type: 'SET_ALL', payload: result?.data?.data });
      dispatch({
        type: 'SET_PAGINATION',
        payload: result?.data?.meta.pagination
      });
    }
  } catch (error) {
    console.log({ error });
    return false;
  }
};

export const createSpecialOffer = async (dispatch, { title, price, points, subtitle, description,features,popular, files, jwt, uploadFiles }) => {
  try {
    let photos = [];
    if (uploadFiles) {
      let uploadFiles = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/upload/`, files, { headers: { Authorization: 'Bearer ' + jwt } });
      if (uploadFiles?.data?.length) photos = uploadFiles?.data?.map((f) => f.id);
    }
    let result = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/special-offers`,
      {
        data: {
          title,
          price,
          points,
          subtitle,
          hero_section_image:photos,
          description,
          features,
          popular
        }
      },
      {
        headers: { Authorization: 'Bearer ' + jwt }
      }
    );

    if (result?.data) {
      return 'OK';
    } else {
      return 'KO';
    }
  } catch (error) {
    console.log({ error });
    return 'KO';
  }
};
export const updateSpecialOffer = async (dispatch, { title, price, points, subtitle, description,features,popular, files, jwt, initialPhotos, id, uploadFiles }) => {
  try {
    let photos = [];
    if (uploadFiles) {
      let uploadFiles = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/upload/`, files, { headers: { Authorization: 'Bearer ' + jwt } });
      if (uploadFiles?.data?.length) photos = uploadFiles?.data?.map((f) => f.id);
    }
    if (initialPhotos) {
      photos = [...photos, ...initialPhotos.map((el) => el.id)];
    }
    let result = await axios.put(
      `${process.env.REACT_APP_SERVER_URL}/api/special-offers/${id}`,
      {
        data: {
          title,
          price,
          points,
          subtitle,
          hero_section_image:photos,
          description,
          features,
          popular
        }
      },
      {
        headers: { Authorization: 'Bearer ' + jwt }
      }
    );

    if (result?.data) {
      return 'OK';
    } else {
      return 'KO';
    }
  } catch (error) {
    console.log({ error });
    return 'KO';
  }
};
export const deleteSpecialOffer = async (dispatch, { id, jwt }) => {
  try {
    let result = await axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/special-offers/${id}`, {
      headers: { Authorization: 'Bearer ' + jwt }
    });

    if (result?.data) {
      return 'OK';
    } else {
      return 'KO';
    }
  } catch (error) {
    console.log({ error });
    return 'KO';
  }
};
