import React, { createContext, useReducer, useContext } from 'react';
import axios from 'axios';

const ReservationsContext = createContext();

const userReducer = (state, action) => {
  switch (action.type) {
    case 'SET_ALL':
      return { ...state, all: action.payload };
    case 'SET_FILTERED':
      return { ...state, filtered: action.payload };
    case 'SET_PAGINATION':
      return { ...state, pagination: action.payload };
    default:
      return state;
  }
};

const initialState = {
  all: [],
  filtered: [],
  pagination: null
};

export const ReservationsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, { ...initialState });

  return <ReservationsContext.Provider value={{ state, dispatch }}>{children}</ReservationsContext.Provider>;
};

export const useReservationsContext = () => useContext(ReservationsContext);

export const fetchAllReservations = async (dispatch, { jwt, page, pageSize, id_session, datetime, name, dateTimeFilter }) => {
  try {
    let request = `${process.env.REACT_APP_SERVER_URL}/api/reservations?populate[user_account][populate]=*&populate[session][populate][room_layout][populate][room][populate]=lines&populate[session][populate][room_layout][populate]=place&populate[session][populate][coach][populate]=photo&populate[session][populate][activity][populate]=*&populate[place][populate]=*&sort[1]=id:desc`;

    if (id_session) {
      request += `&filters[session]=${id_session}`;
    }
    if (datetime) {
      request += `&filters[datetime]=${datetime}`;
    }
    if (name) {
      request += `&filters[$or][0][user_account][firstname][$containsi]=${name}&filters[$or][1][user_account][lastname][$containsi]=${name}`;
    }
    if (dateTimeFilter) {
      const start = transformDate(dateTimeFilter).start;
      const end = transformDate(dateTimeFilter).end;
      request += `&filters[$and][0][datetime][$gte]=${start}`;
      request += `&filters[$and][0][datetime][$lt]=${end}`;
    }
    if (page) {
      request += `&pagination[page]=${page}`;
    }
    if (pageSize) {
      request += `&pagination[pageSize]=${pageSize}`;
    }

    let result = await axios.get(request, {
      headers: {
        Authorization: 'Bearer ' + jwt
      }
    });
    if (result?.data?.meta.pagination) {
      if (!id_session) {
        dispatch({
          type: 'SET_ALL',
          payload: result?.data?.data?.length ? result?.data?.data : []
        });
        dispatch({
          type: 'SET_PAGINATION',
          payload: result?.data?.meta.pagination
        });
      } else {
        dispatch({ type: 'SET_FILTERED', payload: result?.data?.data });
      }
    }
  } catch (error) {
    console.log({ error });
    return false;
  }
};

export const updateReservation = async (dispatch, { jwt, id, place, status }) => {
  try {
    let result = await axios.put(
      `${process.env.REACT_APP_SERVER_URL}/api/reservations/${id}`,
      {
        data: {
          place,
          status
        }
      },
      {
        headers: { Authorization: 'Bearer ' + jwt }
      }
    );

    if (result?.data) {
      return 'OK';
    } else {
      return 'KO';
    }
  } catch (error) {
    console.log({ error });
    return 'KO';
  }
};

export const deleteReservation = async (dispatch, { id, jwt }) => {
  try {
    let result = await axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/reservations/${id}`, {
      headers: { Authorization: 'Bearer ' + jwt }
    });

    if (result?.data) {
      return 'OK';
    } else {
      return 'KO';
    }
  } catch (error) {
    console.log({ error });
    return 'KO';
  }
};

function transformDate(dateString) {
  let date = new Date(dateString);

  let start = new Date(date);
  start.setUTCDate(date.getUTCDate() + 1);
  start.setUTCHours(0, 0, 0, 0);

  let end = new Date(start);
  end.setUTCDate(start.getUTCDate() + 1);
  return {
    start: start.toISOString(),
    end: end.toISOString()
  };
}
