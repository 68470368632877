import DeleteConfirmationModal from '../components/common/DeleteConfirmationModal';
import React, { useEffect, useState } from 'react';
import { Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Paper, Box, Pagination, Alert, Snackbar } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteSpecialOffer, fetchAllSpecialOffers, useSpecialOffersContext } from '../store/specialOffer';
import SpecialOfferModal from '../components/SpecialOffer/SpecialOfferModal';

function SpecialOffer() {
    const { state, dispatch } = useSpecialOffersContext();
    const specialOffers = state.all || [];
    const pagination = state.pagination;
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [editAddModalOpen, setEditAddModalOpen] = useState(false);
    const [currentSpecialOffer, setCurrentSpecialOffer] = useState(null);
    const [specialOfferToDelete, setSpecialOfferToDelete] = useState(null);
    const [activePage, setActivePage] = useState(1);
    const [open, setOpen] = useState(false);
    const [msg, setMsg] = useState(false);
    const [severity, setSeverity] = useState(false);
  
    const pageSize = 10;
  
    useEffect(() => {
      let jwt = localStorage.getItem('jwt');
      fetchAllSpecialOffers(dispatch, { jwt, pageSize, page: activePage });
    }, [activePage]);
  
    const handleEdit = (el) => {
      setCurrentSpecialOffer(el);
      setEditAddModalOpen(true);
    };
  
    const handleDelete = ({ id, id_user }) => {
      let jwt = localStorage.getItem('jwt');
  
      deleteSpecialOffer(dispatch, { jwt, id}).then((res) => {
        setOpen(true);
        if (res === 'OK') {
          fetchAllSpecialOffers(dispatch, { jwt, id });
          setMsg('Special Offer deleted Successfully');
          setSeverity('success');
        } else {
          setMsg('Problem while deleting special offer');
          setSeverity('error');
        }
      });
  
      setDeleteModalOpen(false);
    };
  
    const handleSave = ({ message, variant }) => {
      setOpen(true);
      setMsg(message);
      setSeverity(variant);
      setEditAddModalOpen(false);
      let jwt = localStorage.getItem('jwt');
      fetchAllSpecialOffers(dispatch, { jwt, pageSize, page: 1 });
    };
  
    const handlePageChange = (event, page) => {
      setActivePage(page);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  

  return (
    <>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 3
      }}
    >
      <Typography variant="h4">Special Offers</Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => {
          setCurrentSpecialOffer(null);
          setEditAddModalOpen(true);
        }}
      >
        Add
      </Button>
    </Box>
    <TableContainer component={Paper} sx={{ marginTop: 2, overflowX: 'auto' }}>
      <Table sx={{ tableLayout: 'fixed', width: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ p: 1, width: '20%' }}>title</TableCell>
            <TableCell sx={{ p: 1, width: '20%' }}>price</TableCell>
            <TableCell sx={{ p: 1, width: '20%' }}>points</TableCell>
            <TableCell sx={{ p: 1, width: '30%' }}>subtitle</TableCell>
            <TableCell sx={{ p: 1, width: '10%' }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {specialOffers.map((el) => (
            <TableRow key={el?.id}>
              <TableCell
                sx={{
                  p: 1,
                  width: '20%',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {el?.title} 
              </TableCell>
              <TableCell
                sx={{
                  p: 1,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '20%'
                }}
              >
                {el?.price}
              </TableCell>
              <TableCell
                sx={{
                  p: 1,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '20%'
                }}
              >
                {el?.points}
              </TableCell>
              <TableCell
                sx={{
                  p: 1,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '20%'
                }}
              >
                {el?.subtitle}
              </TableCell>
              <TableCell sx={{ p: 1, width: '20%' }}>
                <IconButton onClick={() => handleEdit(el)} color="primary">
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setSpecialOfferToDelete({
                      id: el?.id,
                    });
                    setDeleteModalOpen(true);
                  }}
                  color="secondary"
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
      {pagination && <Pagination count={pagination.pageCount} page={activePage.page} onChange={handlePageChange} siblingCount={1} boundaryCount={1} shape="rounded" variant="outlined" />}
    </Box>
    <DeleteConfirmationModal
      open={deleteModalOpen}
      handleClose={() => setDeleteModalOpen(false)}
      handleConfirm={() => handleDelete(specialOfferToDelete)}
      title="Are you sure you want to delete this Special Offer ?"
    />
    <SpecialOfferModal open={editAddModalOpen} handleClose={() => setEditAddModalOpen(false)} handleSave={handleSave} initialData={currentSpecialOffer}  />
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} >
      <Alert onClose={handleClose} severity={severity} variant="filled" sx={{ width: '100%' }}>
        {msg}
      </Alert>
    </Snackbar>
  </>
  )
}

export default SpecialOffer